import React, { useContext, useState } from "react";
import { login } from "../services/Api";
import { AuthContext } from "../services/Auth";
import { Redirect } from "react-router-dom";
import { TextInput } from "../forms/Inputs";
const Forgot = () => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const updateUsername = (e) => {
    setUsername(e.target.value);
  };

  const doLookup = (e) => {};

  return (
    <form>
      <div className="login-form gradient-bg flex justify-center items-center">
        <button onClick={() => {}} className="hidden" type="submit">
          Login
        </button>
        <div className="max-w-lg flex flex-col justify-center items-center space-y-4 p-8 bg-white rounded-lg  shadow-md ">
          <h1 className="text-3xl font-bold">INVIIV</h1>
          <p>
            Forgot your password? No worries, we'll get that sorted quickly.
          </p>
          <TextInput
            type="text"
            labelText="E-mail"
            id="username"
            placeholder="Username"
            onChange={updateUsername}
            invalid={errors}
            disabled={loading}
          />

          {errors && (
            <div className="bg-red-100 border-red-700 text-red-700 w-full text-center border-solid border-2 rounded-md p-4">
              {errors}
            </div>
          )}
          <button
            onClick={() => {}}
            className="bg-cta text-xl p-2 w-full text-white hover:bg-cta-dark rounded-lg"
            kind="primary"
            tabIndex={0}
            type="submit"
          >
            Reset password
          </button>
        </div>
      </div>
    </form>
  );
};

export default Forgot;
