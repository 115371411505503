export default {
    primary: '#61DAFB',
    secondary: '#254E70',
    negative: '#e45b78',
    positive: '#A3D9B1',
    textPrimary: '#333',
    backgroundPrimary: '#efefef',
    backgroundSecondary: '#F6F9FC',
    brandLight: '#666',
    brand: '#333',
    brandDark: '#000',
    ctaLight: '#ffdfd1',
    cta: '#fd7d4d',
    ctaDark: '#783f29',
  };