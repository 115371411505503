import React, { useState } from "react";
import { useApiData } from "../utils/useApiData";
import { useFormik, FieldArray, Field, FormikProvider } from "formik";
import * as Yup from "yup";
import ReactMarkdown from "react-markdown";
import { axios } from "../services/Api";
import settingsText from "../md/settings.md";
import { toast } from "react-toastify";
import { timezones } from "../utils/timezones";

const infoText = `# About these settings

Most of these settings are used to configure the behaviour of the portal in your organisation.
These settings will probably have sensible defaults, but you can change them to suit your organisation if needed.

Make sure you know what you are doing before changing these settings, as they may have unexpected consequences.

## Brand Category
If each of your brands have a separate category, set this to the parent category of the brand categories.

## Excluded categories
If you want to exclude certain categories from the product form, enter the category IDs here.

## Product group mapping  
This is the mapping for the product groups in the portal and the product groups in your store.

## Lock colors to list
Locks the product form to the colors in the list.

## Colors
This is the mapping for the colors in the portal and the colors in your store.
`;

const StoreSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required"),
  prefix: Yup.string()
    .min(2, "Prefix should be 2-4 characters")
    .max(4, "Prefix should be 2-4 characters")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const Settings = ({ filter = null }) => {
  const { data } = useApiData("instance");
  return (
    <div className="bg-white p-4 rounded-md shadow-md space-y-8">
      <SettingsInfo />
      <div>
        <h2 className="text-2xl">{data?.name} settings</h2>
        {data?.custom && <SettingsForm settings={data?.custom} />}
      </div>
    </div>
  );
};

const SettingsInfo = () => {
  const [show, setVisible] = useState(false);
  return (
    <>
      <button
        className="flex bg-blue-500 space-x-1 items-center py-2 px-4 text-white rounded-xl"
        onClick={() => setVisible(!show)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>{" "}
        <span>{show ? "Hide" : "Show"} info</span>
      </button>
      {show && (
        <div className="space-y-2 markdown">
          <ReactMarkdown children={infoText} />
        </div>
      )}
    </>
  );
};

const SettingsForm = ({ settings }) => {
  const formik = useFormik({
    initialValues: settings,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      window.confirm("Are you sure you want to save these settings?") &&
        axios.post("/api/stores/instance", { custom: values }).then((res) => {
          toast(res.data.message, {
            autoClose: true,
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE[res.data.state],
          });
        });
    },
  });
  if (!formik.values) return null;
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="flex flex-col">
        <label className="text-lg underline" htmlFor="brand_cat">
          Brand Category
        </label>
        <Field
          id="brand_cat"
          name="brand_cat"
          type="number"
          onChange={formik.handleChange}
          value={formik.values.brand_cat}
          className="mb-4 w-80"
        />
        <label className="text-lg underline" htmlFor="clubPriceList">
          Club Price-list (GUID)
        </label>
        <Field
          id="clubPriceList"
          name="clubPriceList"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.clubPriceList}
          className="mb-4 w-80"
        />
        <label className="text-lg underline" htmlFor="storeOnlyStatus">
          Store only status id
        </label>
        <Field
          id="storeOnlyStatus"
          name="storeOnlyStatus"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.storeOnlyStatus}
          className="mb-4 w-80"
        />

        <FieldArray
          name="exclude_categories"
          render={(arrayHelpers) => (
            <div>
              <label className="text-lg underline">Excluded categories</label>
              {formik.values.exclude_categories.length === 0 && (
                <p className="text-danger my-4">No excluded categories.</p>
              )}
              {formik.values.exclude_categories.map((friend, index) => (
                <div key={index}>
                  <Field
                    className="w-80"
                    type="number"
                    name={`exclude_categories.${index}`}
                    onChange={formik.handleChange}
                    value={formik.values.exclude_categories[index]}
                  />

                  <button
                    type="button"
                    className="p-2 bg-danger w-10 text-white h-full border border-danger"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="p-2 bg-success w-80 text-white h-full border border-success mb-4"
                onClick={() => arrayHelpers.push(0)}
              >
                +
              </button>
            </div>
          )}
        />
        <FieldArray
          name="groups"
          render={(arrayHelpers) => (
            <div>
              <label className="text-lg underline">Product group mapping</label>
              {formik.values.groups.length === 0 && (
                <p className="text-danger my-4">No groups mapped.</p>
              )}
              {formik.values.groups.map((friend, index) => (
                <div key={index}>
                  <Field
                    className="w-40"
                    type="text"
                    name={`groups.${index}.0`}
                    onChange={formik.handleChange}
                    value={formik.values.groups[index][0]}
                  />
                  <Field
                    className="w-40"
                    type="text"
                    name={`groups.${index}.1`}
                    onChange={formik.handleChange}
                    value={formik.values.groups[index][1]}
                  />

                  <button
                    type="button"
                    className="p-2 bg-danger w-10 text-white h-full border border-danger"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="p-2 bg-success w-80 text-white h-full border border-success mb-4"
                onClick={() => arrayHelpers.push(["", ""])}
              >
                +
              </button>
            </div>
          )}
        />

        <FieldArray
          name="vat_rates"
          render={(arrayHelpers) => (
            <div>
              <label className="text-lg underline">
                VAT-rates (Use percentages without %)
              </label>
              {formik.values.vat_rates.length === 0 && (
                <p className="text-danger my-4">No excluded categories.</p>
              )}
              {formik.values.vat_rates.map((friend, index) => (
                <div key={index}>
                  <Field
                    className="w-80"
                    type="number"
                    name={`vat_rates.${index}`}
                    onChange={formik.handleChange}
                    value={formik.values.vat_rates[index]}
                  />

                  <button
                    type="button"
                    className="p-2 bg-danger w-10 text-white h-full border border-danger"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="p-2 bg-success w-80 text-white h-full border border-success mb-4"
                onClick={() => arrayHelpers.push(0)}
              >
                +
              </button>
            </div>
          )}
        />

        <FieldArray
          name="customFields"
          render={(arrayHelpers) => (
            <div>
              <label className="text-lg underline">Custom Fields</label>
              {formik.values.customFields?.length === 0 && (
                <p className="text-danger my-4">No custom field settings.</p>
              )}
              {formik.values.customFields?.map((friend, index) => (
                <div key={index}>
                  <Field
                    className="w-40"
                    placeholder="Name"
                    type="text"
                    name={`customFields.${index}.name`}
                  />
                  <Field
                    className="w-40"
                    placeholder="Values"
                    type="text"
                    name={`customFields.${index}.value`}
                  />

                  <button
                    type="button"
                    className="p-2 bg-danger w-10 text-white h-full border border-danger"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="p-2 bg-success w-80 text-white h-full border border-success"
                onClick={() => arrayHelpers.push({ name: "", value: "" })}
              >
                +
              </button>
            </div>
          )}
        />

        <div className="space-x-4 flex items-center">
          <label htmlFor="lock_colors" className="text-lg underline">
            Lock colors to list
          </label>
          <Field type="checkbox" id="lock_colors" name="lock_colors" />
        </div>

        <FieldArray
          name="colorList"
          render={(arrayHelpers) => (
            <div>
              <label className="text-lg underline">Colors</label>
              {formik.values.colorList.length === 0 && (
                <p className="text-danger my-4">No colors in list.</p>
              )}
              {formik.values.colorList.map((friend, index) => (
                <div key={index}>
                  <Field
                    className="w-40"
                    placeholder="Name"
                    type="text"
                    name={`colorList.${index}.0`}
                    onChange={formik.handleChange}
                    value={formik.values.colorList[index][0]}
                  />
                  <Field
                    className="w-40"
                    placeholder="Hex-code"
                    type="text"
                    name={`colorList.${index}.1`}
                    onChange={formik.handleChange}
                    value={formik.values.colorList[index][1]}
                  />

                  <button
                    type="button"
                    className="p-2 bg-danger w-10 text-white h-full border border-danger"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    -
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="p-2 bg-success w-80 text-white h-full border border-success"
                onClick={() => arrayHelpers.push(["", ""])}
              >
                +
              </button>
            </div>
          )}
        />
        <label htmlFor="brand_subname" className="text-lg underline">
          Use brand as subname
        </label>
        <Field type="checkbox" id="brand_subname" name="brand_subname" />

        <label htmlFor="brand_subname" className="text-lg underline">
          Store timezone
        </label>
        <Field as="select" name="timezone">
          <option value="">---</option>
          {timezones.map((timezone) => (
            <option value={timezone}>{timezone}</option>
          ))}
        </Field>
        <button className="bg-success p-4 text-lg mt-4 w-80" type="submit">
          Save
        </button>
      </form>
    </FormikProvider>
  );
};
