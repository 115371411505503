import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RequiredMarker } from "./Inputs";
import { ErrorMessage } from "formik";

function Wysiwyg({field,form,...props}) {
    const handleChange = (e) => {
        form.setFieldValue(field.name, e)
    }

  return (
    <div className="relative w-full mt-4">
    {props.labelText && <label className="block pb-2">{props.labelText}{props.required && <RequiredMarker/>}</label>}
    <div className="relative text-xl w-full mb-6">
        <ReactQuill theme="snow" value={field.value ?? ''} onChange={handleChange}/>
        {props.errors && props.field?.name in props.errors && <div className="absolute top-10 left-0 p-1 h-6 text-xs bg-danger text-white font-bold w-full">{props.errors[props.field?.name]}</div>}
    
        </div>
        {props.helpText && <span className="block p-1 text-xs -mt-6">{props.helpText}</span>}
        {props.field?.name &&
        <ErrorMessage name={props.field?.name} >{msg => <div className="py-2 mb-6 text-danger">{msg}</div>}</ErrorMessage>
    }
</div>

  );
}

export { Wysiwyg };