import React, { useState, useMemo } from "react";
import { useApiData } from "../utils/useApiData";
import { useTable, useFilters, useSortBy } from "react-table";
import dayjs from "dayjs";
import { axios } from "../services/Api";
import relativeTime from "dayjs/plugin/relativeTime";
import { toast } from "react-toastify";
import { DefaultColumnFilter } from "../components/Filters";
import { useFormik, Field, FormikProvider } from "formik";
import { Loader } from "../forms/Loader";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";
dayjs.extend(relativeTime);
export const orderStatus = {
  1: "Received",
  2: "Processing",
  3: "Processed",
  4: "Shipped",
  13: "Ready for pickup",
  12: "Return being processed",
  5: "Returned",
  9: "Canceled",
};

/**
 * The StatusChanger function is a React component that allows the user to change the status of an order.
 *
 *
 * @param {initial} Used to Pass the initial value of the status.
 * @return A formik form with a select field and submit button.
 *
 * @doc-author Trelent
 */
const StatusChanger = ({ initial }) => {
  const formik = useFormik({
    initialValues: {
      status: initial.value,
      order: initial.row.values.order_id,
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      axios
        .get("/api/stores/setorderstatus", {
          params: {
            ...values,
          },
        })
        .then(function (res) {
          toast(res.data.message, {
            autoClose: true,
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE[res.data.state],
          });
        });
      // window.confirm('Are you sure you want to save these settings?') && axios.post('/api/stores/instance',{custom:values}).then(res=>{
      //   console.log(res.data.state)
      //   toast(res.data.message, { autoClose: true ,position: toast.POSITION.TOP_RIGHT,type: toast.TYPE[res.data.state]})
      // })
    },
  });
  if (!formik.values) return null;
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="flex">
        <Field
          as="select"
          name="status"
          onChange={formik.handleChange}
          className="w-40"
        >
          {Object.entries(orderStatus).map(([key, value]) => {
            return (
              <option key={key} value={key}>
                {value}
              </option>
            );
          })}
        </Field>
        <button
          className="bg-success text-white px-2"
          type="submit"
          disabled={!formik.dirty}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </button>
      </form>
    </FormikProvider>
  );
};

const columns = [
  {
    accessor: "show_more",
    Header: "",
    disableFilters: true,
  },
  {
    accessor: "order_id",
    Header: "Order.nr.",
  },
  {
    accessor: "shipping",
    Header: "Shipping",
  },
  {
    accessor: "created",
    Header: "Date",
    disableFilters: true,
  },
  {
    accessor: "status",
    Header: "Status",
    Cell: ({ value }) => orderStatus[value],
    filterMethod: (filter, row) => {
      return true;
    },
    disableFilters: true,
  },
];

export const Orders = ({ prio = false }) => {
  const { search } = useLocation();
  const { offset } = queryString.parse(search);
  const pageOffset = offset ?? 0;
  console.log(pageOffset);
  const { data, loading, refetch } = useApiData(
    `orders/?offset=${pageOffset}`,
    {
      refetch: 600,
      fallback: [],
    }
  );
  console.log(data);
  const authCheck = useApiData("is_super");
  const [expanded, setExpanded] = useState(null);
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const filteredData = useMemo(() => {
    if (data && prio) {
      return data.filter((item) => item.status < 3);
    }
    return data;
  }, [prio, data]);

  const tableInstance = useTable(
    {
      columns,
      data: filteredData,
      defaultColumn,
      filterTypes,
      initialState: {
        sortBy: [{ id: "order_id", desc: true }],
      },
    },
    useFilters,
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handleExpand = (id) => {
    if (expanded === id) {
      setExpanded(null);
    } else {
      setExpanded(id);
    }
  };

  const cellParser = (cell) => {
    if (cell.column.id === "created") {
      if (dayjs(cell.value).isBefore(dayjs(), "week")) {
        return dayjs(cell.value).format("YYYY-MM-DD HH:mm");
      }
      return dayjs(cell.value).fromNow(true);
    }
    return cell.render("Cell");
  };

  const setPackaged = (id) => {
    axios
      .get("/api/stores/set_packaged?id=" + id)
      .then((response) => {
        if (response.data.success) {
          toast('Product marked "Ready for pickup"', {
            autoClose: true,
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.SUCCESS,
          });
          refetch();
        } else {
          toast("Something went wrong...", {
            autoClose: false,
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.ERROR,
          });
        }
      })
      .catch(() => {
        console.log("Nä...");
      });
  };

  const setReturned = (id) => {
    axios
      .get("/api/stores/set_returned?id=" + id)
      .then((response) => {
        if (response.data.success) {
          toast('Product marked as "Returned"', {
            autoClose: true,
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.SUCCESS,
          });
          refetch();
        } else {
          toast("Something went wrong...", {
            autoClose: false,
            position: toast.POSITION.TOP_RIGHT,
            type: toast.TYPE.ERROR,
          });
        }
      })
      .catch(() => {
        console.log("Nä...");
      });
  };

  return (
    <>
      <h1 className="text-2xl font-bold mb-4 text-white">
        {prio && "Pending "}Orders
      </h1>
      <div className="flex space-x-4 mb-4 items-end bg-gray-200 rounded-md overflow-hidden shadow-md">
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) =>
            // Apply the header row props

            headerGroup.headers.map(
              (column) =>
                column.canFilter && (
                  <div {...column.getHeaderProps()} className="m-2">
                    <span className="text-xs">{column.render("Header")}</span>
                    <div>{column.render("Filter")}</div>
                  </div>
                )
            )
          )
        }
      </div>
      <div className="flex mb-4 items-end bg-gray-200 rounded-md overflow-hidden shadow-md">
        {pageOffset !== 0 && (
          <Link
            className="bg-warning p-4 hover:bg-warning-700"
            to={`/orders?offset=${parseInt(pageOffset) - 100}`}
          >
            Previous page
          </Link>
        )}
        <Link
          className="bg-warning p-4 hover:bg-warning-700"
          to={`/orders?offset=${parseInt(pageOffset) + 100}`}
        >
          Next page
        </Link>
      </div>
      <table
        {...getTableProps()}
        className="w-full rounded overflow-hidden shadow-md"
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="bg-black text-white p-2 text-left"
                    >
                      <div className="flex items-center justify-between">
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 15l7-7 7 7"
                              />
                            </svg>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <tr>
              <td className="p-20 text-center" colSpan={columns.length + 1}>
                <Loader />
              </td>
            </tr>
          ) : (
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <React.Fragment key={row.id}>
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        if (cell.column.id === "show_more") {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="w-2 text-left"
                              onClick={() => {
                                handleExpand(row.id);
                              }}
                            >
                              <svg
                                className={`h-6 w-6 ${
                                  expanded === row.id && "transform rotate-180"
                                }`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            </td>
                          );
                        }
                        if (
                          cell.column.id === "status" &&
                          authCheck.data.isSuper
                        ) {
                          return (
                            <td {...cell.getCellProps()}>
                              <StatusChanger initial={cell} refetch={refetch} />
                            </td>
                          );
                        }
                        return (
                          <td
                            className="overflow-hidden"
                            {...cell.getCellProps()}
                          >
                            {cellParser(cell)}
                          </td>
                        );
                      })
                    }
                  </tr>
                  {expanded === row.id && (
                    <tr>
                      <td colSpan="5" className="p-2 border-blue-400 border">
                        <h2 className="font-bold text-xl">Details for order</h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row.original.shipping.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br/>"
                            ),
                          }}
                        />
                        <table className="w-full">
                          <thead>
                            <tr>
                              <th className="text-left">Name</th>
                              <th className="text-left">Variant</th>
                              <th className="text-left small">
                                Price (EX.VAT)
                              </th>
                              <th className="text-left">Status</th>
                              <th className="text-right">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.original.products.map((product) => (
                              <tr key={product.id}>
                                <td>{product.product.name}</td>
                                <td>{product.variant}</td>
                                <td className="small">{product.exVat}</td>
                                <td>
                                  {product.returned
                                    ? "Returned"
                                    : product.packed
                                    ? "Ready for pickup"
                                    : "Waiting"}
                                </td>
                                <td className="space-x-2 text-right overflow-visible">
                                  <button
                                    className="has-tooltip bg-success p-2 rounded-md text-white hover:bg-success-light"
                                    disabled={product.packed}
                                    onClick={() => setPackaged(product.id)}
                                  >
                                    <span className="tooltip tooltip-right">
                                      Mark "Ready for pickup"
                                    </span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                                      />
                                    </svg>
                                  </button>

                                  <button
                                    className="has-tooltip bg-warning p-2 rounded-md text-white hover:bg-warning-light"
                                    disabled={product.returned}
                                    onClick={() => setReturned(product.id)}
                                  >
                                    <span className="tooltip tooltip-right">
                                      Mark as returned
                                    </span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-6 w-6"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z"
                                      />
                                    </svg>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </table>
      <div className="flex mb-4 items-end bg-gray-200 rounded-md overflow-hidden shadow-md  mt-4">
        {parseInt(pageOffset) !== 0 && (
          <Link
            className="bg-warning p-4 hover:bg-warning-700"
            to={`/orders?offset=${parseInt(pageOffset) - 100}`}
          >
            Previous page
          </Link>
        )}
        <Link
          className="bg-warning p-4 hover:bg-warning-700"
          to={`/orders?offset=${parseInt(pageOffset) + 100}`}
        >
          Next page
        </Link>
      </div>
    </>
  );
};
