import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import Login from "./Login";
import Forgot from "./Login/forgot";
import { AuthProvider, AuthContext } from "./services/Auth";
import Header from "./components/Header";
import SideNav from "./components/SideNav";
import { Profile } from "./routes/Profile";
import { Orders } from "./routes/Orders";
import { Products } from "./routes/Products";
import { Stores } from "./routes/Stores";
import { Settings } from "./routes/Settings";
import { AddForm } from "./routes/AddForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { applyTheme } from "./themes/utils";

window.applyTheme = applyTheme;

dayjs.extend(relativeTime);

const ProtectedRoute = ({ children, ...rest }) => {
  const { authenticated } = useContext(AuthContext);
  if (!authenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...rest}>{children}</Route>;
};

const Loading = () => {
  return (
    <div className="bg-black flex items-center justify-center text-5xl">
      Loading...
    </div>
  );
};

const Dpa = () => {
  return (
    <div className="bg-white prose p-4 rounded-md  max-w-prose m-auto">
      <h1 className="text-2xl font-bold mb-4 ">DATA PROCESSING AGREEMENT</h1>
      <table className="infotable">
        <tr>
          <th>(1)</th>
          <td>
            [PART], [REG. NO.], hereinafter referred to as “the Data
            Controller”, with address [ADDRESS], [POST CODE], and
          </td>
        </tr>
        <tr>
          <th>(2)</th>
          <td>
            R3 Sweden AB, 556715-8802 hereinafter referred to as “the Data
            Processor”, with address Nils Jakobsonsgatan 5, 504 30 Borås,
            Sweden,
          </td>
        </tr>
        <tr>
          <th>
            <strong>1.</strong>
          </th>
          <th>
            <strong>Ingress</strong>
          </th>
        </tr>
        <tr>
          <th>
            <strong>1.1</strong>
          </th>
          <td>
            European Parliament and Council Regulation (EU) 2020/679,
            hereinafter referred to as the General Data Protection Regulation,
            states that processing of personal data can be processed by a data
            processor on behalf of the data controller. Such processing must be
            regulated in an agreement and in accordance with documented
            instructions from the data controller.
          </td>
        </tr>
        <tr>
          <th>1.2</th>
          <td>
            Should the General Data Protection Regulation contain terms that
            conflict with those used in the Agreement, such terms shall be
            interpreted and applied in accordance with the General Data
            Protection Regulation.
          </td>
        </tr>
        <tr>
          <th> 1.3</th>
          <td>
            The Data Controller uses the service “Invive” which is provided by
            the Data Processor. To be able to provide Invive the Data Processor
            will process personal data on behalf of the Data Controller. This
            agreement regulates the parties relationship regarding processing of
            personal data.
          </td>
        </tr>
        <tr>
          <th>
            <strong>2.</strong>
          </th>
          <th>
            <strong>OBLIGATIONS OF THE DATA CONTROLLER</strong>
          </th>
        </tr>
        <tr>
          <th>2.1</th>
          <td>
            The Data Controller is responsible for ensuring that all processing
            of personal data complies with the Agreement and all Applicable
            Regulations (such as the General Data Protection Regulation,
            national law, guidelines from authorities and court practise) and
            advice.
          </td>
        </tr>
        <tr>
          <th>2.2 </th>
          <td>
            The Data Controller shall provide the Data Processor with the
            information and personal data that are necessary and appropriate for
            the purpose of enabling the latter to fulfil its obligations in
            accordance with the Agreement and the Applicable Regulations.
          </td>
        </tr>
        <tr>
          <th>2.3</th>
          <td>
            The documented instructions from the Data Controller to the Data
            Processor can be found in section 3 below.
          </td>
        </tr>
        <tr>
          <th>2.4 </th>
          <td>
            The Data Controller shall provide the Data Processor with the
            correct information promptly in the event that the documented
            instructions are incorrect, incomplete or otherwise need to be
            amended.
          </td>
        </tr>

        <tr>
          <th>3</th>
          <th>INSTRUCTIONS</th>
        </tr>
        <tr>
          <th>3.1</th>
          <td>
            The Data Processor will, to be able to provide Inviv, process
            personal data on behalf of the Data Controller. The purpose of this
            processing is to publish products and process received orders from
            customers.
          </td>
        </tr>
        <tr>
          <th>3.2</th>
          <td>
            Personal data that will be processed is names, addresses, telephone
            numbers, IP-addresses, passwords (encrypted), order data and any
            potential comments in free text that can include personal data.
          </td>
        </tr>
        <tr>
          <th>3.3</th>
          <td>
            The Data Processor shall receive personal data from the e-commerce
            platform of the Data Controller when a customer makes an order for
            products/services on the Internet. The personal data will thereafter
            be available for the Data Controller in Inviv during 90 days
            whereafter the Data Processor shall deidentify the data for
            statistical purposes and delete all personal data.
          </td>
        </tr>
        <tr>
          <th>4</th>
          <th> OBLIGATIONS OF THE DATA PROCESSOR</th>
        </tr>
        <tr>
          <th>4.1</th>
          <td>
            The Data Processor shall only process personal data on behalf of the
            Data Controller in compliance with the Agreement, the Instructions
            and Applicable Regulations. The Data Processor shall also ensure
            that the basic principles for the processing of personal data are
            respected, including storage minimisation. The Data Processor is
            responsible for thinning personal data that are no longer required
            for the purpose. The Data Processor shall carry out modifications,
            deletions, limitations and transfers at the explicit request of the
            Data Controller, but not, however, if such request contravenes the
            Agreement or Applicable Regulations. The Data Processor shall
            establish procedures for how the personal data are thinned, what
            personal data are thinned and how often the thinning is carried out.
          </td>
        </tr>
        <tr>
          <th>4.2</th>
          <td>
            The Data Processor shall take suitable technical and organisational
            measures to ensure a level of security that is appropriate for the
            risk and ensure that employees, consultants and any other person
            that the Data Processor is responsible for and that processes
            personal data are bound by a suitable confidentiality undertaking
            and are informed on how the processing of the personal data shall be
            made.
          </td>
        </tr>
        <tr>
          <th>4.3</th>
          <td>
            The Data Processor shall maintain a record of processing activities
            of all categories of processing that is carried out on behalf of the
            Data Controller, according to the Applicable Regulations.
          </td>
        </tr>
        <tr>
          <th>4.4</th>
          <td>
            The Data Processor may not transfer any personal data to a state
            outside of the EU area or to a state to which the exceptions to the
            prohibition of transfer to third countries, as stated in the
            Applicable Regulations, do not apply. The prohibition also applies
            to servicing, technical support, maintenance, development and
            similar services of the system.
          </td>
        </tr>
        <tr>
          <th>5</th>
          <td>AUDIT AND DISCLOSURE OF PERSONAL DATA</td>
        </tr>
        <tr>
          <th>5.1</th>
          <td>
            The Data Processor shall notify the Data Controller without undue
            delay if the Data Processor is contacted by a supervisory authority,
            data subject or third party for the purpose of viewing the personal
            data that is processed by the Data Processor. The Data Processor
            must aid the Data Controller in complying with such a demand in
            accordance with Applicable Regulations.
          </td>
        </tr>
        <tr>
          <th>5.2</th>
          <td>
            The Data Controller has the right to carry out, or to employ a third
            party to carry out, an audit of the work of the Data Processor or
            otherwise check that the Data Processor’s processing of personal
            data complies with the Agreement and Applicable Regulations. The
            Data Processor shall give the Data Controller the assistance that is
            required for the implementation of such audit. The Data Processor
            has the right to bill the Data Controller for any costs rising from
            such an audit.
          </td>
        </tr>
        <tr>
          <th>6</th>
          <th> SUB-PROCESSOR</th>
        </tr>
        <tr>
          <th>6.1</th>
          <td>
            The Data Processor may not hire another Data Processor
            (Sub-Processor) without obtaining a specific or general written
            prior authorisation from the Data Controller.
          </td>
        </tr>
        <tr>
          <th>7</th>
          <th>PERSONAL DATA BREACHES</th>
        </tr>
        <tr>
          <th>7.1</th>
          <td>
            In the event of a suspected or discovered personal data breach, the
            Data Processor shall investigate the breach immediately and take
            suitable measures to mitigate its potential negative effects. The
            Data Processor shall also within 48 hours from gaining knowledge of
            the personal data breach inform the Data Controller by handing over
            a description of the personal data breach.
          </td>
        </tr>
        <tr>
          <th>7.2</th>
          <td>A description of the personal data breach shall a include:</td>
        </tr>
        <tr>
          <th>i)</th>
          <td>
            a description of the type of personal data breach, including where
            possible the categories of and the approximate number of data
            subjects concerned, as well as the categories of and approximate
            number of personal data items concerned,
          </td>
        </tr>
        <tr>
          <th>ii)</th>
          <td>
            a description of the likely consequences of the personal data
            breach,
          </td>
        </tr>
        <tr>
          <th>iii)</th>
          <td>
            a description of the measures that have been taken or proposed by
            the Data Processor to remedy the personal data breach, and
          </td>
        </tr>
        <tr>
          <th>iv)</th>
          <td>
            the name of and contact details for the data protection officer or
            other contact points from where further information may be obtained,
          </td>
        </tr>
        <tr>
          <th>8</th>
          <th> LIABILITY FOR DAMAGE</th>
        </tr>
        <tr>
          <th>8.1</th>
          <td>
            The Data Processor shall only be liable to the Data Controller for
            damage caused as a result of the processing of personal data if it
            has not fulfilled the obligations according to the Applicable
            Regulations that apply specifically to the Data Processor or has
            acted outside or in conflict with the Agreement.
          </td>
        </tr>
        <tr>
          <th>8.2</th>
          <td>
            The Data Controller is, in relations to the Data Processor, liable
            for all damages due to incorrect or inadequate Instructions.
          </td>
        </tr>
        <tr>
          <th>9</th>
          <th>REMUNERATION</th>
        </tr>
        <tr>
          <th>9.1</th>
          <td>
            The Data Processor has no right to renumeration for the work being
            done to fulfill the obligations of this Agreement. This does however
            not apply if the Instructions are changed by the Data Controller
            during the contract period or if the Data Controller in any other
            way causes extra work for the Data Processor as for example extended
            data processing that could not have been foreseen at the entering
            into of this Agreement.
          </td>
        </tr>
        <tr>
          <th>10</th>
          <th> CONTRACT PERIOD</th>
        </tr>
        <tr>
          <th>10.1</th>
          <td>
            The Agreement is valid from the date of signing by the Parties and
            for as long as the Data Processor processes personal data in
            accordance with the Data Controller’s instructions, however never
            longer than Inviv is available on the market.
          </td>
        </tr>
        <tr>
          <th>10.2</th>
          <td>
            After the processing on behalf of the Data Controller has been
            completed, the Data Controller shall within 14 days instruct the
            Data Processor to return or delete the personal data. If the
            personal data are to be handed back, this shall be done without
            undue delay and in a common and legible electronic format. If no
            such instruction is given by the Data Controller within 14 days the
            Data Processor shall act as if having been instructed to delete the
            Personal Data if nothing else follows by law or Applicable
            Regulations.
          </td>
        </tr>
      </table>
    </div>
  );
};

const Pub = () => {
  return (
    <div className="bg-white prose p-4 rounded-md  max-w-prose m-auto">
      <h1 className="text-2xl font-bold mb-4 ">PERSONUPPGIFTSBITRÄDESAVTAL</h1>
      <table className="infotable">
        <tr>
          <th>(1)</th>
          <td>
            [PART], [PERS.NR/ORG.NR], nedan kallad ”Personuppgiftsansvarig”, med
            adress [ADRESS], [POSTADRESS], och
          </td>
        </tr>
        <tr>
          <th>(2)</th>
          <td>
            R3 Sweden AB, 556715-8802 nedan kallad ” ”Personuppgiftsbiträdet”,
            med adress Nils Jakobsonsgatan 5, 504 30 Borås,
          </td>
        </tr>
        <tr>
          <th>
            <strong>1.</strong>
          </th>
          <th>
            <strong>Ingress</strong>
          </th>
        </tr>
        <tr>
          <th>
            <strong>1.1</strong>
          </th>
          <td>
            Rådets förordning (EU) 2016/679 om skydd för fysiska personer med
            avseende på behandling av personuppgifter (”Dataskyddsförordningen”)
            föreskriver att det behandling av personuppgifter på
            personuppgiftsansvarigs vägnar kan ske av personuppgiftsbiträde.
            Sådan behandling av personuppgiftsbiträde måste regleras i avtal och
            i enlighet med dokumenterade instruktioner från
            personuppgiftsansvarig.
          </td>
        </tr>
        <tr>
          <th>1.2</th>
          <td>
            Begrepp som används i detta avtal ska ha samma innebörd som
            Dataskyddsförordningen.
          </td>
        </tr>
        <tr>
          <th> 1.3</th>
          <td>
            Personuppgiftsansvarig nyttjar tjänsten Inviiv som
            Personuppgiftsbiträdet tillhandahåller. För att kunna tillhandahålla
            Inviiv kommer Personuppgiftsbiträdet behöva behandla personuppgifter
            för Personuppgiftsansvarigs räkning. Detta avtal syftar till att
            reglera parternas förhållande beträffande behandling av
            personuppgifter.
          </td>
        </tr>
        <tr>
          <th>
            <strong>2.</strong>
          </th>
          <th>
            <strong>PERSONUPPGIFTSANSVARIGES SKYLDIGHETER</strong>
          </th>
        </tr>
        <tr>
          <th>2.1</th>
          <td>
            Personuppgiftsansvarig ansvarar för att all behandling av
            personuppgifter sker i enlighet med detta avtal och tillämpliga
            bestämmelser (såsom dataskyddsförordningen, nationell
            kompletteringslagstiftning, föreskrifter och från tillsynsmyndighet
            inklusive sådana från den europeiska dataskyddsstyrelsen), praxis
            samt råd.
          </td>
        </tr>
        <tr>
          <th>2.2 </th>
          <td>
            Personuppgiftsansvarige ska tillhandahålla Personuppgiftsbiträdet
            med den information och de uppgifter som behövs för att
            Personuppgiftsbiträdet ska kunna fullgöra sina skyldigheter enligt
            detta avtal och tillämpliga bestämmelser.
          </td>
        </tr>
        <tr>
          <th>2.3</th>
          <td>
            Personuppgiftsansvarige instruktioner till Personuppgiftsbiträdet
            framgår av punkt 3 (”Instruktionerna”).
          </td>
        </tr>
        <tr>
          <th>2.4 </th>
          <td>
            Personuppgiftsansvarige ska omedelbart lämna Personuppgiftsbiträdet
            korrekta uppgifter i händelse av att de dokumenterade
            instruktionerna är felaktiga, ofullständiga eller i övrigt behöver
            förändras.
          </td>
        </tr>

        <tr>
          <th>3</th>
          <th>INSTRUKTIONER</th>
        </tr>
        <tr>
          <th>3.1</th>
          <td>
            Personuppgiftsbiträdet kommer för att kunna tillhandahålla Inviiv
            behandla personuppgifter för Personuppgiftsansvarigs räkning. Syftet
            med behandlingen är att kunna publicera produkter och hantera
            mottagna order från kund.
          </td>
        </tr>
        <tr>
          <th>3.2</th>
          <td>
            Personuppgifter som ska behandlas avser namn, adress, telefonnummer,
            e-postadress, IP-adress, lösenord (krypterat), orderdata samt
            eventuella fritextkommentarer som dessa uppger och som kan innehålla
            personuppgifter.
          </td>
        </tr>
        <tr>
          <th>3.3</th>
          <td>
            Personuppgiftsbiträdet ska ta emot personuppgifter från
            Personuppgiftsansvariges e-handelsplattform i samband med att kund
            lägger beställning produkter/tjänster över internet.
            Personuppgifterna finns därefter tillgängliga för
            Personuppgiftsansvarig i Inviiv under 90 dagar varefter
            Personuppgiftsbiträdet ska avidentifiera uppgifterna för statistiska
            ändamål och radera alla Personuppgifter.
          </td>
        </tr>
        <tr>
          <th>4</th>
          <th>PERSONUPPGIFTSBITRÄDETS SKYLDIGHETER</th>
        </tr>
        <tr>
          <th>4.1</th>
          <td>
            Personuppgiftsbiträdet ska endast behandla personuppgifter för
            Personuppgiftsansvariges räkning enligt villkoren i detta avtal,
            Instruktionerna och enligt tillämpliga bestämmelser.
            Personuppgiftsbiträdet ska också genomföra gallring av
            personuppgifter i enlighet med Instruktionerna samt genomföra
            ändringar, raderingar, begränsningar och överföringar på
            Personuppgiftsansvariges uttryckliga begäran, dock inte om en sådan
            begäran strider mot avtalet eller tillämpliga bestämmelser.
          </td>
        </tr>
        <tr>
          <th>4.2</th>
          <td>
            Personuppgiftsbiträdet ska vidta lämpliga tekniska och
            organisatoriska säkerhetsåtgärder för att skydda personuppgifterna
            samt ska tillse att anställda, konsulter och övriga som
            Personuppgiftsbiträdet svarar för och som behandlar
            personuppgifterna är bundna av ett ändamålsenligt sekretessåtagande
            samt informerade om hur behandling av personuppgifter får ske.
          </td>
        </tr>
        <tr>
          <th>4.3</th>
          <td>
            Personuppgiftsbiträdet ska föra register över sina behandlingar i
            enlighet med tillämpliga bestämmelser.
          </td>
        </tr>
        <tr>
          <th>4.4</th>
          <td>
            Personuppgiftsbiträdet får inte överföra personuppgifter till tredje
            man och får heller inte lämna ut information om
            personuppgiftsbehandlingen till tredje man, utan att i förväg
            inhämta skriftligt godkännande av den Personuppgiftsansvarige om
            inte sådan skyldighet föreligger enligt tillämpliga bestämmelser.
          </td>
        </tr>
        <tr>
          <th>5</th>
          <td>REVISION SAMT BEGÄRAN OM INFORMATION</td>
        </tr>
        <tr>
          <th>5.1</th>
          <td>
            Personuppgiftsbiträdet ska underrätta Personuppgiftsansvarige om
            denne får kännedom om att behandling sker i strid mot tillämpliga
            bestämmelser eller om tillsynsmyndighet, registrerad eller tredje
            part kontaktat Personuppgiftsbiträdet i syfte att få tillgång till
            personuppgifter som Personuppgiftsbiträdet behandlar för
            Personuppgiftsansvarigs räkning. Personuppgiftsbiträdet är skyldig
            att bistå Personuppgiftsansvarig för utlämnande av sådana uppgifter
            i enlighet med tillämpliga bestämmelser.
          </td>
        </tr>
        <tr>
          <th>5.2</th>
          <td>
            Personuppgiftsansvarige äger rätt att, själv eller genom tredje man,
            genomföra revision gentemot Personuppgiftsbiträdet eller på annat
            sätt kontrollera att Personuppgiftsbiträdets behandling av
            personuppgifter följer avtalet och tillämpliga bestämmelser. Vid
            sådan revision eller kontroll ska Personuppgiftsbiträdet ge
            Personuppgiftsansvarige den assistans som behövs för genomförande av
            revision. Personuppgiftsbiträdet äger rätt till ersättning från
            Personuppgiftsansvarig för de kostnader som uppkommer vid sådan
            revision eller kontroll.
          </td>
        </tr>
        <tr>
          <th>6</th>
          <th>UNDERBITRÄDE</th>
        </tr>
        <tr>
          <th>6.1</th>
          <td>
            Personuppgiftsbiträdet får inte anlita annat personuppgiftsbiträde
            (underbiträde) för behandling av personuppgifter för den
            Personuppgiftsansvariges räkning utan skriftligt samtycke från den
            Personuppgiftsansvarige.
          </td>
        </tr>
        <tr>
          <th>7</th>
          <th>PERSONUPPGIFTSINCIDENT</th>
        </tr>
        <tr>
          <th>7.1</th>
          <td>
            Personuppgiftsbiträdet ska vid konstaterad eller misstänkt
            säkerhetsincident, såsom obehörig åtkomst, förstörelse, ändring
            eller annan otillåten påverkan avseende personuppgifter omedelbart
            undersöka incidenten och vidta lämpliga åtgärder för att åtgärda
            densamma och förhindra upprepning samt inom 48 timmar från upptäckt
            informera den Personuppgiftsansvarige genom att tillhandahålla
            incidentrapport.
          </td>
        </tr>
        <tr>
          <th>7.2</th>
          <td>En Incidentrapport ska åtminstone innehålla:</td>
        </tr>
        <tr>
          <th>i)</th>
          <td>
            beskrivning av incidentens art, samt om möjligt, de kategorier av
            samt det ungefärliga antal registrerade som berörs och det
            ungefärliga antal personuppgiftsposter som berörs
          </td>
        </tr>
        <tr>
          <th>ii)</th>
          <td>beskrivning av de sannolika konsekvenserna av incidenten</td>
        </tr>
        <tr>
          <th>iii)</th>
          <td>åtgärdsplan för att mildra potentiellt negativa effekter.</td>
        </tr>
        <tr>
          <th>iv)</th>
          <td>
            kontaktuppgifter för erhållande av ytterligare information om
            incidenten.
          </td>
        </tr>
        <tr>
          <th>8</th>
          <th>ANSVAR FÖR SKADA</th>
        </tr>
        <tr>
          <th>8.1</th>
          <td>
            Personuppgiftsbiträdet svarar endast gentemot Personuppgiftsansvarig
            för skada som uppkommit till följd av att behandling av
            personuppgifter skett i strid med detta avtal eller skyldigheter
            enligt tillämpliga bestämmelser som specifikt riktas mot
            personuppgiftsbiträden.
          </td>
        </tr>
        <tr>
          <th>8.2</th>
          <td>
            Personuppgiftsansvarig svarar i förhållande till
            Personuppgiftsbiträdet för all skada som uppkommit till följd av att
            dennes Instruktioner varit felaktiga eller bristfälliga.
          </td>
        </tr>
        <tr>
          <th>9</th>
          <th>ERSÄTTNING</th>
        </tr>
        <tr>
          <th>9.1</th>
          <td>
            Personuppgiftsbiträdet har inte rätt till särskild ersättning för
            fullgörande av skyldigheter enligt detta avtal. Detta gäller dock
            inte ifall Personuppgiftsansvarig under avtalstiden ändrar
            Instruktionerna eller på annat sätt orsakar merarbete för
            Personuppgiftsbiträdet såsom t.ex. utökad behandling av
            personuppgifter som inte förutsetts vid ingående av detta avtal.
          </td>
        </tr>
        <tr>
          <th>10</th>
          <th>AVTALSTID</th>
        </tr>
        <tr>
          <th>10.1</th>
          <td>
            Detta avtal gäller från undertecknande och tillsvidare så länge
            Personuppgiftsbiträde behandlar personuppgifter åt
            Personuppgiftsansvarig dock längst till när Inviiv upphör
            tillhandahållas.
          </td>
        </tr>
        <tr>
          <th>10.2</th>
          <td>
            Efter att avtalet upphör ska Personuppgiftsansvarig inom fjorton
            (14) dagar meddela Personuppgiftsbiträdet om personuppgifter som
            behandlats för Personuppgiftsansvarigs räkning ska återlämnas eller
            raderas. Ska personuppgifterna återlämnas ska det ske i ett allmänt
            och elektroniskt läsbart format. Lämnar Personuppgiftsansvarig inte
            sådant meddelande inom föreskriven tid ska Personuppgiftsbiträdet
            radera uppgifterna om inte annat följer av tillämpliga bestämmelser.
          </td>
        </tr>
      </table>
    </div>
  );
};

const Policy = () => {
  return (
    <div className="bg-white prose p-4 rounded-md  max-w-prose m-auto policypage">
      <h1 className="text-2xl font-bold mb-4 ">Policy</h1>
      <p>
        Inviiv's policy does not permit content or references to content that:
      </p>
      <ol type="a">
        <li>
          Promotes hatred, racism or the like, describes or shows cruel or
          brutal acts of violence.
        </li>
        <li>Violates human dignity.</li>
        <li>Glorifies war or violence.</li>
        <li>
          Describes or shows assault, harassment or sexual exploitation of
          children.
        </li>
        <li>Describes or shows sexual acts between humans and animals.</li>
        <li> May affect children and young people immorally or harmfully.</li>
      </ol>
      <p>
        The Account Holder and its users are solely responsible for all content
        published on the server, and for ensuring that the content does not
        constitute an infringement of intellectual property and other
        proprietary rights. Because Inviiv does not systematically monitor or
        has editorial oversight of the content of the Account Holder's website,
        Inviiv reserves the right to remove all links to a third party's website
        or other content that in Inviiv's reasonable opinion may violate any law
        or third party's rights, regardless of nature, or otherwise makes, or
        possibly makes, Inviiv guilty of civil disobedience or crime. The
        account holder agrees to indemnify Inviiv and hold Inviiv free from
        liability and to defend Inviiv with its own funds from all allegations
        or claims of crime in accordance with the above policy. Inviiv reserves
        the right to freely examine whether these policies are followed by the
        Account Holder. Inviiv has the right to check the information on the
        Account Holder's storage space. If an Account Holder breaches the
        Agreement, Inviiv reserves the right to block the information deemed
        inappropriate. If the violation is serious, the Account Holder may be
        suspended in whole or in part from continued use. Maintenance and care
        of the material is performed by the Account Holder via Inviiv's
        administration tool.
      </p>
    </div>
  );
};

export function Dashboard() {
  const { instance, hasPriv } = useContext(AuthContext);
  useEffect(() => {
    const theme = window.location.host.replace(".inviiv.io");
    applyTheme(theme);
  }, []);

  if (!instance || instance.loading) {
    console.log("Loadingstate");
    return <Loading />;
  }

  return (
    <>
      <Router>
        <SideNav />
        <main className="data-table mx-auto max-w-screen-xl w-full min-h-screen p-4 pl-48">
          <Switch>
            <Route path="/dpa">
              <Dpa />
            </Route>
            <Route path="/pub">
              <Pub />
            </Route>
            <Route path="/policy">
              <Policy />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/forgot">
              <Forgot />
            </Route>
            <ProtectedRoute exact path="/">
              <Orders prio={true} />
            </ProtectedRoute>
            <ProtectedRoute path="/profile">
              <Profile />
            </ProtectedRoute>
            <ProtectedRoute path="/orders">
              <Orders />
            </ProtectedRoute>
            <ProtectedRoute path="/products/new">
              <AddForm />
            </ProtectedRoute>
            <ProtectedRoute path="/products/:id">
              <AddForm />
            </ProtectedRoute>
            <ProtectedRoute path="/products">
              <Products />
            </ProtectedRoute>
            {hasPriv && (
              <>
                <ProtectedRoute path="/stores">
                  <Stores />
                </ProtectedRoute>
                <ProtectedRoute path="/settings">
                  <Settings />
                </ProtectedRoute>
              </>
            )}
          </Switch>
          <a
            href="https://status.inviiv.io/"
            className="statusLink"
            target="_blank"
          >
            Status & Uptime info
          </a>
        </main>
        <ToastContainer />
      </Router>
    </>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <Dashboard />
    </AuthProvider>
  );
}
