import { useApiData } from "../utils/useApiData";
import { Fragment, useContext, useMemo, useRef } from "react";
import { AuthContext } from "../services/Auth";
import React from "react";
import { ErrorMessage } from "formik";
import { RequiredMarker } from "./Inputs";
import { Loader } from "./Loader";

export const CategorySelect = React.memo(
  ({ name = "category", field, form, data, brands = false, ...props }) => {
    const { instance } = useContext(AuthContext);
    const storeData = useApiData("stores/", { fallback: [] });

    const currentStore = useMemo(() => {
      if (!storeData?.data) return null;
      return storeData.data.find(
        (store) => parseInt(store.id) === parseInt(props.store)
      );
    }, [storeData?.data, props?.store]);

    const excludes = useMemo(() => {
      if (
        !brands ||
        (instance?.custom && "exclude_categories" in instance?.custom)
      ) {
        if (!currentStore) return null;
        return instance.custom.exclude_categories;
      }

      return null;
    }, [data?.length, instance?.name, currentStore]);

    const filteredData = useMemo(() => {
      if (!data || !excludes || brands) return null;
      const excludedData = data.filter((d) => {
        return excludes.indexOf(d.id) == -1;
      });
      return excludedData;
    }, [excludes, data?.length]);

    const storeCategory = useMemo(() => {
      if (instance?.custom && "brand_cat" in instance?.custom) {
        if (!currentStore) return null;
        const brandCat =
          data?.find((cat) => cat.id === instance.custom.brand_cat) ?? null;
        if (!brandCat) return null;
        return brandCat?.subcategories.find((subCat) => {
          return subCat.name.toLowerCase() === currentStore.name.toLowerCase();
        });
      }

      return null;
    }, [data?.length, instance?.name, currentStore]);

    const storeList = useMemo(() => {
      if (instance?.custom && "brand_cat" in instance?.custom) {
        if (!currentStore) return null;
        const brandCat =
          data?.find((cat) => cat.id === instance.custom.brand_cat) ?? null;
        if (!brandCat) return null;
        return brandCat?.subcategories;
      }

      return null;
    }, [data?.length, instance?.name, currentStore]);

    if (!data) {
      return <Loader />;
    }
    console.log(instance);
    const mapCats = (cats, lvl = 0, parent = []) =>
      cats?.map((cat) => {
        const values = [...parent, cat.id].filter((id) => id !== 391);
        const unique = values.filter((v, i) => values.indexOf(v) === i);
        return (
          <Fragment key={cat.id}>
            <option value={unique.join()} disabled={unique.length < 1}>
              {" - ".repeat(lvl)}
              {cat.name}
            </option>
            {cat.subcategories && mapCats(cat.subcategories, lvl + 1, values)}
          </Fragment>
        );
      });

    return (
      <div>
        <label className="block pb-2">
          {brands ? "Brand" : "Category"}
          {props.required && <RequiredMarker />}
        </label>
        <select name={name} {...field} className="input-select w-full">
          <option value={""}>
            --- No {brands ? "brand" : "category"} selected ---
          </option>
          {brands
            ? storeList?.map((brand) => {
                return (
                  <option key={brand.id} value={brand.id}>
                    {brand.name}
                  </option>
                );
              })
            : mapCats(filteredData)}
        </select>
        {!brands && (
          <span className="block p-1 text-xs ">
            The product will also be added to all parent categories
          </span>
        )}
        <ErrorMessage name={field?.name}>
          {(msg) => <div className="py-2 mb-6 text-danger">{msg}</div>}
        </ErrorMessage>
      </div>
    );
  }
);
