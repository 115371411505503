import {createContext,useState,useMemo} from 'react'
import {isLoggedIn} from './Api'
import {useApiData} from '../utils/useApiData'
export const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const [auth,setAuth] = useState(isLoggedIn())
    const {data} = useApiData('user')
    const inst = useApiData('instance')
    const updateAuth = () => {
        setAuth(isLoggedIn())
    }
    const hasPriv = useMemo(()=>{
        if(data?.group?.length === 0){
            return false;
        }
        if(data?.group?.includes('superuser')){
            return true;
        }
        if(auth){
            inst.refetch()
        }
        return false;
    },[data,auth])


    return(
    <AuthContext.Provider value={{authenticated:auth,updateAuth:updateAuth,userInfo:data,hasPriv,instance:inst?.data}}>
        {children}
    </AuthContext.Provider>
    )

}