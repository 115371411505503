
import { useContext, useEffect } from 'react'
import { AuthContext } from '../services/Auth'
import React from 'react'
import { ErrorMessage } from 'formik'
import { RequiredMarker } from './Inputs'

export const percentToVatRate = (percent) => {
    return parseFloat(((percent / 100)).toFixed(2))
}

export const VatSelect = React.memo(({name='vatRate',field,form,...props}) => {
    const {instance} = useContext(AuthContext)
    

    if(instance?.custom?.vat_rates?.length < 1) return null
    return(
        <div>
        <label className="block pb-2">VAT-Rate{props.required && <RequiredMarker/>}</label>
        <select  name={name} {...field} className="input-select w-full">
            {instance?.custom?.vat_rates.map(vat => <option key={vat} value={percentToVatRate(vat)}>{vat}%</option>)}
        </select>
        <span className="block p-1 text-xs ">Select the VAT-rate for your product</span>
        <ErrorMessage name={field?.name} >{msg => <div className="py-2 mb-6 text-danger">{msg}</div>}</ErrorMessage>
        </div>
    )
})