import { useApiData } from "../utils/useApiData";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { useEffect } from "react";

export const CustomFields = ({ values, setFieldValue, instance }) => {
  const { data } = useApiData("templates");
  const TYPES = {
    2: "text",
    3: "checkbox",
  };

  console.log(data, instance.custom);
  if (!data) return null;
  return (
    <div className="w-full p-4 bg-gray-200">
      <p className="italic text-xs text-red-800 mb-2">
        <sup>*</sup>The design and user interface for custom fields is
        temporary, and will be greatly improved in the future.
      </p>
      <p className="italic text-xs mb-2">
        <sup>**</sup>The sync for these fields are not activated at this point.
        But the data will be saved.
      </p>
      <h2 className="text-2xl mb-4">Custom fields</h2>
      <Field
        as="select"
        name="productData.customFields.template"
        className="input-select w-full"
      >
        <option value={null}>-- Select template --</option>
        {data.map((v) => (
          <option key={v.TemplateId} value={v.TemplateId}>
            {v.InternalName}
          </option>
        ))}
      </Field>

      {data
        ?.find(
          (v) =>
            v?.TemplateId ===
            parseInt(values?.productData?.customFields?.template)
        )
        ?.fields.map((v, i) => {
          let type = null;
          const special = instance.custom?.customFields.find(
            (vv) => vv.name === v.ItemName
          );
          if (special) {
            if (special.value.split(",").length > 1) {
              type = "select";
            }
          }
          return (
            <div>
              <label
                className="block mt-6"
                htmlFor={`productData.customFields.fields.${i}.value`}
              >
                {v.ItemName}
              </label>
              {type === "select" ? (
                <Field
                  as="select"
                  name={`productData.customFields.fields.${i}.value`}
                  id={`productData.customFields.fields.${i}.value`}
                  className="input-select"
                >
                  <option value={null}>-- Select {v.ItemName} --</option>
                  {special.value.split(",").map((sp) => (
                    <option key={sp} value={sp}>
                      {sp}
                    </option>
                  ))}
                </Field>
              ) : (
                <Field
                  type={TYPES[v.Type]}
                  name={`productData.customFields.fields.${i}.value`}
                  id={`productData.customFields.fields.${i}.value`}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};
