import { themes } from './index';

  export const mapTheme = (variables)=> {
    return {
      '--color-primary': variables.primary || '',
      '--color-secondary': variables.secondary || '',
      '--color-positive': variables.positive || '',
      '--color-negative': variables.negative || '',
      '--color-text-primary': variables.textPrimary || '',
      '--background-primary': variables.backgroundPrimary || '',
      '--cta-light': variables.ctaLight || '',
      '--cta': variables.cta || '',
      '--cta-dark': variables.ctaDark || '',
      '--brand-light': variables.brandLight || '',
      '--brand': variables.brand || '',
      '--brand-dark': variables.brandDark || '',
    };
  };

  export const applyTheme = (tryTheme) => {

    let theme = 'helsinki'
    if(tryTheme in themes) {
      theme = tryTheme
    }

      console.log(theme,themes);
      
    const themeObject = mapTheme(themes[theme]);
    if (!themeObject) return;
  
    const root = document.documentElement;
  
    Object.keys(themeObject).forEach((property) => {
      if (property === 'name') {
        return;
      }
  
      root.style.setProperty(property, themeObject[property]);
    });
  };


