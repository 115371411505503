import React, { useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { useApiData } from "../utils/useApiData";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import dayjs from "dayjs";
import { AuthContext } from "../services/Auth";
import { DefaultColumnFilter } from "../components/Filters";
import { Loader } from "../forms/Loader";
import { axios } from "../services/Api";
import { toast } from "react-toastify";

const calcStock = (product) => {
  try {
    //Only has basestock
    if (!product.productData.hasColors && !product.productData.hasSizes) {
      return product.productData.baseStock;
    }
    //Only has single lvl variants
    if (!product.productData.hasColors || !product.productData.hasSizes) {
      const stockData = product.productData.variants.reduce(function (
        previousValue,
        currentValue,
        currentIndex,
        array
      ) {
        return {
          stock: parseInt(previousValue.stock) + parseInt(currentValue.stock),
        };
      });

      return stockData.stock;
    }
    //Has multiple lvl variants
    console.log("Is the next one broken?");
    const stockData = product.productData.variants.reduce(
      function (previousValue, currentValue, currentIndex, array) {
        const current = currentValue?.lvl2?.reduce(function (
          previousValue,
          currentValue,
          currentIndex,
          array
        ) {
          return {
            stock:
              parseInt(previousValue?.stock ?? 0) +
              parseInt(currentValue?.stock ?? 0),
          };
        });
        console.log("reducer?", previousValue, parseInt(current?.stock ?? 0));

        return {
          stock: previousValue.stock + parseInt(current?.stock ?? 0),
        };
      },
      { stock: 0 }
    );
    if (!stockData.stock) {
      console.log("Verify", product);
    }
    return stockData.stock;
  } catch (e) {
    console.log(e);
    return "Error";
  }
};

export const Products = ({ filter = null }) => {
  const { data, loading, refetch } = useApiData("products/", {
    refetch: 600,
    fallback: [],
  });
  console.log(data);
  const categories = useApiData("categories");

  const mappedCategories = useMemo(() => {
    return (
      categories?.data?.flatMap((cat) => {
        if (!cat.subcategories) {
          return [{ name: cat.name, id: cat.id }];
        }
        return [
          { name: cat.name, id: cat.id },
          ...cat.subcategories.flatMap((subCat) => [
            { name: `- ${subCat.name}`, id: subCat.id },
          ]),
        ];
      }) ?? []
    );
  }, [categories?.data]);
  const { hasPriv } = useContext(AuthContext);
  const columns = useMemo(() => {
    let cols = [];
    const isBig = window.innerWidth > 600;

    if (isBig) {
      cols.push(
        {
          accessor: "last_edit",
          Header: "Last edit",
          disableFilters: true,
          defaultCanSort: true,
        },
        {
          accessor: "stock_total",
          Header: "Stock",
          disableFilters: true,
          defaultCanSort: true,
        },
        {
          Header: "Category",
          accessor: "productData.category",
          Filter: ({
            column: { filterValue, setFilter, preFilteredRows, id },
          }) => {
            return (
              <>
                <select
                  className="p-2 rounded-sm border-none text-xs w-28"
                  value={filterValue}
                  onChange={(e) => {
                    setFilter(e.target.value || undefined);
                  }}
                >
                  <option value="">All</option>
                  {mappedCategories.map((cat) => (
                    <option value={cat.id}>{cat.name}</option>
                  ))}
                </select>
              </>
            );
          },

          Cell: ({ value }) => {
            console.log(value, "test");
            let newValue = [];
            try {
              newValue = value
                ? value?.split(",").map((cat) => {
                    return mappedCategories.find((c) => c.id === parseInt(cat));
                  })
                : [];
            } catch (error) {
              console.log("Kategori buggade");
            }

            return (
              <span
                dangerouslySetInnerHTML={{
                  __html: newValue.map((c) => c?.name).join("<br/>"),
                }}
              />
            );
          },
        },
        {
          accessor: "is_removed",
          Header: "",
          Filter: ({
            column: { filterValue, setFilter, preFilteredRows, id },
          }) => {
            return (
              <>
                <span className="text-xs block">Show removed</span>
                <select
                  className="p-2 rounded-sm border-none text-xs w-28"
                  value={filterValue}
                  onChange={(e) => {
                    setFilter(e.target.value || undefined);
                  }}
                >
                  <option value="">All</option>
                  <option value={false}>Non removed</option>
                  <option value={true}>Removed</option>
                </select>
              </>
            );
          },
        },
        {
          accessor: "productData.hideProduct",
          Header: "Is visible",
          Cell: ({ value, row }) =>
            value || row.original.is_removed ? (
              <>
                <span className="tag tag-fail">No</span>
              </>
            ) : (
              <span className="tag tag-success">Yes</span>
            ),
          Filter: ({
            column: { filterValue, setFilter, preFilteredRows, id },
          }) => {
            return (
              <select
                className="p-2 rounded-sm border-none text-xs w-20"
                value={filterValue}
                onChange={(e) => {
                  setFilter(e.target.value || undefined);
                }}
              >
                <option value="">All</option>
                <option value={false}>Visible</option>
                <option value={true}>Hidden</option>
              </select>
            );
          },
        }
      );
    }

    cols.unshift(
      {
        accessor: "image",
        Header: "Image",
        disableFilters: true,
        defaultCanSort: true,
      },
      {
        accessor: "name",
        Header: "Name",
        defaultCanSort: true,
      },
      {
        accessor: "product_artno",
        Header: "Art.no.",
        defaultCanSort: true,
      }
    );

    cols.push({
      accessor: "sync_status",
      Header: "Status",
      disableFilters: true,
    });

    return cols;
  }, [categories?.data]);

  const handleRemove = (id) => {
    axios("api/stores/remove?p=" + id).then((res) => {
      if (res.data.message === "Success") {
        toast(res.data.detail, {
          autoClose: true,
          position: toast.POSITION.TOP_RIGHT,
          type: toast.TYPE.SUCCESS,
        });
        refetch();
      }
    });
  };

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        filters: [
          {
            id: "is_removed",
            value: false,
          },
        ],
      },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  // const addNew = () => {
  //   history.push('/products/new')
  // }
  const cellParser = (cell) => {
    if (cell.column.id === "image") {
      if (cell?.row?.original?.productData?.images?.length > 0) {
        return (
          <img
            src={cell?.row?.original?.productData?.images[0]?.url}
            className="w-16 rounded-sm shadow-sm"
          />
        );
      }
    }
    if (cell.column.id === "sync_status") {
      if (cell?.row?.original?.is_removed) {
        return <span className="tag tag-fail">Removed</span>;
      }
      if (cell?.row?.original?.remove) {
        return <span className="tag tag-fail">Removing</span>;
      }
      if (cell?.row?.original?.has_errors) {
        return <span className="tag tag-fail">ERROR</span>;
      }

      if (cell.value) {
        return <span className="tag tag-success">Synced</span>;
      } else {
        return <span className="tag tag-fail">Waiting</span>;
      }
    }
    if (cell.column.id === "last_edit") {
      if (dayjs(cell.value).isBefore(dayjs(), "week")) {
        return dayjs(cell.value).format("YYYY-MM-DD HH:mm");
      }
      return dayjs(cell.value).fromNow(true);
    }
    if (cell.column.id === "stock_total") {
      console.log(calcStock(cell.row.original));
      return calcStock(cell.row.original);
    }
    return cell.render("Cell");
  };

  return (
    <>
      <div className="flex space-x-4 mb-4 items-end bg-gray-200 rounded-md  overflow-hidden shadow-md">
        <Link
          className="bg-success  flex w-44 h-20 p-2 justify-evenly items-center transition-all text-white shadow-md hover:shadow-lg hover:bg-success-dark"
          to="/products/new"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
          <span>Add new Product</span>
        </Link>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) =>
            // Apply the header row props

            headerGroup.headers.map(
              (column) =>
                column.canFilter && (
                  <div {...column.getHeaderProps()} className="m-2">
                    <span className="text-xs">{column.render("Header")}</span>
                    <div>{column.render("Filter")}</div>
                  </div>
                )
            )
          )
        }
      </div>
      <table
        {...getTableProps()}
        className="w-full rounded-md overflow-hidden shadow-md"
      >
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center justify-between">
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 15l7-7 7 7"
                              />
                            </svg>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </th>
                  ))}
                  <th className="bg-brand text-white p-2 text-right">
                    Actions
                  </th>
                </tr>
              </>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <tr>
              <td className="p-20 text-center" colSpan={columns.length + 1}>
                <Loader />
              </td>
            </tr>
          ) : (
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  {...row.getRowProps()}
                  className={`${
                    row.original.remove && "text-gray-400 line-through"
                  }`}
                >
                  {
                    // Loop over the rows cells

                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          className="overflow-hidden"
                          {...cell.getCellProps()}
                        >
                          {cellParser(cell)}
                        </td>
                      );
                    })
                  }
                  <td className="flex items-center justify-end">
                    <div className="flex overflow-hidden items-center justify-end rounded text-white">
                      <Link
                        className="p-1 bg-gray-500 transition-all hover:bg-gray-700"
                        to={`/products/${row.original.id}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </Link>
                      <button
                        onClick={() => {
                          handleRemove(row.original.product_artno);
                        }}
                        className={`p-1 transition-all ${
                          row.original.remove
                            ? "bg-success hover:bg-success-dark"
                            : "bg-red-600 hover:bg-red-800"
                        }`}
                      >
                        {row.original.remove ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <div className="w-full flex justify-between py-4 items-center">
        <div className="space-x-2">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="p-2 bg-cta rounded-md"
          >
            {"<<"}
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="p-2 bg-cta rounded-md"
          >
            {"<"}
          </button>
        </div>
        <span>
          Page
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 10000000].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize > 500 ? "all (not recommended)" : pageSize}
            </option>
          ))}
        </select>
        <div className="space-x-2">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="p-2 bg-cta rounded-md"
          >
            {">"}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="p-2 bg-cta rounded-md"
          >
            {">>"}
          </button>
        </div>
      </div>
    </>
  );
};
