import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { axios } from "../services/Api";
import { Field, FieldArray } from "formik";

const UploadFiles = ({ values, setFieldValue }) => {
  const [state, setState] = useState({
    loading: false,
    files: [],
    images: values.productData?.images ?? [],
  });

  const handleFiles = (e) => {
    const files = Array.from(e.target.files);

    setState({ ...state, files: files });
  };

  const doUpload = (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const formData = new FormData();
    state.files.forEach((file, i) => {
      formData.append(i, file);
    });
    axios
      .post("/api/stores/imageupload", formData)
      .then(handleUploadResponse)
      .catch(function (error) {
        console.log(error);
        toast("Something went wrong while uploading...", {
          autoClose: false,
          position: toast.POSITION.TOP_RIGHT,
          type: toast.TYPE.ERROR,
        });
      });
  };

  const handleUploadResponse = (res) => {
    if (res.data.success) {
      res.data.result.forEach((img, i) => {
        console.log("test", img);
        setFieldValue(
          `productData.images.${
            (values.productData?.images?.length ?? 0) + i
          }.url`,
          img
        );
      });
      setState({ ...state, loading: false, files: [] });
      toast("Image uploaded", {
        autoClose: true,
        position: toast.POSITION.TOP_RIGHT,
        type: toast.TYPE.SUCCESS,
      });
    } else {
      setState({ ...state, loading: false, files: [] });
      toast("Something went wrong...", {
        autoClose: true,
        position: toast.POSITION.TOP_RIGHT,
        type: toast.TYPE.ERROR,
      });
    }
  };

  return (
    <>
      <h2 className="text-2xl mb-4 mt-6">Add Images</h2>
      <div>
        <label className="w-full flex flex-col items-center px-4 py-2 bg-white rounded-md border border-blue cursor-pointer hover:bg-cta hover:text-white text-cta ease-linear transition-all duration-150">
          <input
            type="file"
            id="single"
            className="hidden"
            onChange={handleFiles}
            multiple
            accept=".jpg,.jpeg,.gif,.png"
          />
          {state?.files.length > 0 ? (
            <ul className="my-4 w-full">
              <li className="flex justify-between font-bold">
                Image name <span>Size</span>
              </li>
              {state.files.map((file) => (
                <li className="flex justify-between">
                  {file.name}
                  <span className="text-sm">
                    {file.size > 10485760 ? (
                      <span className=" text-red-600">File to large</span>
                    ) : (
                      <>({humanFileSize(file.size, true)})</>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                />
              </svg>
              <span className="mt-2 text-base leading-normal">
                Select files
              </span>
              <span className="text-xs">
                (supported formats: jpg, gif, png. Max size: 10mb)
              </span>
            </>
          )}
        </label>
        {state?.files.length > 0 && (
          <>
            <button
              onClick={doUpload}
              className="flex items-center items-center justify-center px-4 py-4 mt-4 bg-cta text-white sborder border-blue cursor-pointer hover:bg-cta-dark ease-linear transition-all duration-150"
            >
              Upload selected
            </button>
            <span className="block p-2 text-xs">
              You need to push the upload button for the image to be saved.
            </span>
          </>
        )}
        {values?.productData?.images?.length > 0 && (
          <>
            <h2 className="text-2xl mb-4 mt-6">Uploaded Images</h2>
            <div className="grid grid-cols-4 grid-flow-row gap-4">
              <FieldArray
                name={`productData.images`}
                render={(arrayHelpers) =>
                  values.productData.images.map((img, index) => (
                    <UploadedFile
                      arrayHelpers={arrayHelpers}
                      key={index}
                      img={img}
                      index={index}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const UploadedFile = ({ img, index, values, setFieldValue, arrayHelpers }) => {
  const removeImage = (e) => {
    arrayHelpers.remove(index);
  };

  return (
    <div className="bg-white relative overflow-hidden flex items-center justify-center shadow-md hover:shadow-lg transition-shadow rounded-md h-36">
      <span
        onClick={removeImage}
        className="bg-danger p-2 text-white rounded-bl-md absolute top-0 right-0 block"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </span>
      <img src={img?.url} />
      {img?.url && (
        <>
          <Field name={`productData.images.${index}.variant`} type="hidden" />
          <Field
            name={`productData.images.${index}.url`}
            type="hidden"
            readOnly={true}
          />
        </>
      )}
    </div>
  );
};

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export default UploadFiles;
