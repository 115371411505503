import { Redirect, useParams } from "react-router-dom";
import { Formik, Field, Form, FieldArray } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { axios } from "../services/Api";
import { TextInput, TextArea, Button } from "../forms/Inputs";
import UploadFiles from "../forms/ImageUpload";
import { CategorySelect } from "../forms/CategorySelect";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { StoreSelect } from "./StoreSelect";
import { useApiData } from "../utils/useApiData";
import { Wysiwyg } from "../forms/Wysiwyg";
import { percentToVatRate, VatSelect } from "../forms/VatSelect";
import { AuthContext } from "../services/Auth";
import { CustomFields } from "../forms/CustomFields";

function validateArtno(value) {
  let error;
  if (!value) {
    error = "All products need an unique art.no.";
  }
  return error;
}

function validateCategory(value) {
  let error;
  if (!value) {
    error = "You need to add a category";
  }
  return error;
}

function validateBasic(value) {
  let error;
  if (!value) {
    error = "This field is required";
  }
  return error;
}

function validateStock(value) {
  let error;

  if (isNaN(value)) {
    error = "This has to be a number";
  }
  return error;
}

function validateTime(value) {
  let error;
  console.log("TEST", value);
  if (typeof value === "undefined") {
    error = "You have to set a date and time";
  }
  return error;
}

const DateTimeField = ({ name, labelText, form, field }) => {
  return (
    <label className="my-4 block">
      {labelText}
      <input type="date" {...field} className="block" />
    </label>
  );
};
const TimeField = ({ name, labelText, form, field }) => {
  return (
    <label className="my-4 block">
      {labelText}
      <input type="time" {...field} className="block" />
    </label>
  );
};

export const AddForm = ({ filter = null }) => {
  const { instance } = useContext(AuthContext);
  const categories = useApiData("categories");
  const defaultVat = percentToVatRate(instance?.custom?.vat_rates?.[0]);

  const init = {
    name: "",
    article_number: "",
    description: "",
    store: "",
    sync_status: false,
    productData: {
      category: "",
      attributes: [],
      basePrice: 0,
      hasSizes: false,
      hasColors: false,
      baseDiscountPrice: 0,
      sizes: [],
      colors: [],
      store: false,
      online: true,
      baseStock: 0,
      vatRate: defaultVat,
    },
  };
  const [formData, setFormData] = useState({
    loading: false,
    error: false,
    status: null,
    initial: init,
  });
  const params = useParams();
  const toastId = useRef(null);

  useEffect(() => {
    if (params?.id && formData.initial?.name === "") {
      setFormData({ ...formData, loading: true });
      axios
        .get("/api/stores/products/" + params.id + "/")
        .then((response) => {
          setFormData({ ...formData, loading: false, initial: response.data });
        })
        .catch(() => {
          setFormData({ ...formData, loading: false, error: true });
        });
    }
  }, [params?.id]);

  if (formData.status === "Created") {
    return <Redirect to="/products?success" />;
  }

  if (formData.error) {
    return (
      <div>
        <h1 className="text-xl font-bold">Something went wrong</h1>
        <p>The product couldn't be found.</p>
        <p>Or you lack the permissions required to view it.</p>
      </div>
    );
  }
  return (
    <div>
      <Formik
        initialValues={formData.initial}
        enableReinitialize
        className=""
        onSubmit={async (values, actions) => {
          setFormData({ ...formData, loading: true });
          const handleResponse = (response) => {
            toast.update(toastId.current, {
              render: "Product was saved!",
              type: toast.TYPE.SUCCESS,
              autoClose: 5000,
            });
            setFormData({
              ...formData,
              loading: false,
              status: response.statusText,
            });
          };
          const handleError = (error) => {
            let message = "Something went wrong!";
            if (error.response?.data?.info == "artno") {
              message = "The article number is already in use.";
              actions.setFieldError(
                "article_number",
                "You have used this article number before."
              );
            }

            toast.update(toastId.current, {
              render: message,
              type: toast.TYPE.ERROR,
              autoClose: 5000,
            });
            setFormData({
              ...formData,
              loading: false,
              status: error.statusText,
            });
          };
          toastId.current = toast("Saving product", {
            autoClose: false,
            position: toast.POSITION.TOP_RIGHT,
          });
          if (params?.id) {
            axios
              .patch(`/api/stores/products/${params.id}/`, values)
              .then(handleResponse)
              .catch(handleError);
          } else {
            axios
              .post("/api/stores/products/", values)
              .then(handleResponse)
              .catch(handleError);
          }
        }}
      >
        {({ values, setFieldValue, dirty, errors }) => (
          <>
            {console.log(errors)}
            <Form className="space-y-4 bg-white max-w-4xl rounded-md overflow-hidden flex flex-col items-center w-full m-auto">
              <SyncStatus
                values={values}
                setFieldValue={setFieldValue}
                dirty={dirty}
              />

              <div className="max-w-4xl w-full px-4">
                <h2 className="text-2xl mb-4">Product info</h2>
                <Field type="hidden" name="sync_status" value={false} />
                <Field
                  type="text"
                  required
                  name="name"
                  labelText="Name"
                  component={TextInput}
                  validate={validateBasic}
                />

                <Field
                  type="text"
                  required
                  helpText="Your articlenumber will be prefixed with your store prefix"
                  validate={validateArtno}
                  name="article_number"
                  labelText="Art.no."
                  component={TextInput}
                  disabled={formData.initial.article_number.length > 0}
                  validate={validateArtno}
                />
                <label className="flex items-center mt-4">
                  <Field
                    className="form-checkbox w-5 h-5 mr-2"
                    type="checkbox"
                    name="productData.hideProduct"
                  />
                  Hide product
                </label>
                <Field
                  name="productData.description"
                  labelText="Description"
                  component={Wysiwyg}
                />
                <Field
                  name="productData.basePrice"
                  type="number"
                  labelText="Base Price"
                  helpText="This price will be used for all variants without a price"
                  component={TextInput}
                  validate={validateStock}
                />
                <Field
                  name="productData.baseDiscountPrice"
                  type="number"
                  labelText="Base Discounted Price"
                  component={TextInput}
                />
                <Field
                  name="productData.vatRate"
                  required
                  type="number"
                  labelText="VAT-rate"
                  component={VatSelect}
                />
                <Field
                  name="productData.category"
                  {...categories}
                  component={CategorySelect}
                  values={values}
                  store={values.store}
                  required
                  validate={validateCategory}
                />
                <Field
                  name="productData.brandCategory"
                  {...categories}
                  brands={true}
                  component={CategorySelect}
                  values={values}
                  store={values.store}
                />
                <Field
                  type="text"
                  name="productData.publishDate"
                  labelText="Publish Date"
                  component={DateTimeField}
                />
                <Field
                  type="text"
                  name="productData.publishTime"
                  labelText="Publish Time"
                  component={TimeField}
                />
                <Field
                  type="text"
                  name="productData.removalDate"
                  labelText="Removal Date"
                  component={DateTimeField}
                />
                <Field
                  type="text"
                  name="productData.removalTime"
                  labelText="Removal Time"
                  component={TimeField}
                />
                <ProductStates />
                <StoreSelect values={values} setFieldValue={setFieldValue} />
                <UploadFiles values={values} setFieldValue={setFieldValue} />
              </div>
              <CustomFields
                values={values}
                setFieldValue={setFieldValue}
                instance={instance}
              />

              <div className="max-w-4xl w-full px-4">
                <h2 className="text-2xl mb-4">Attributes</h2>

                <ListHandler values={values} />
                <ListHandler values={values} attrib="sizes" />
              </div>
              <div className="max-w-4xl w-full px-4">
                <VariantSettings
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>

              {Object.keys(errors).length > 0 && (
                <dl className="p-4 border border-red-500 m-4 bg-red-50 rounded-md space-y-2">
                  {console.log(Object.entries(errors))}
                  {Object.entries(errors).map(([key, value]) => {
                    if (key == "productData") {
                      return Object.entries(value).map(([key2, value2]) => {
                        return (
                          <>
                            <dt className="uppercase  font-bold">{key2}</dt>
                            <dd>
                              {typeof value2 == "string" ? value2 : "ERROR"}
                            </dd>
                          </>
                        );
                      });
                    }
                    return (
                      <>
                        <dt className="uppercase  font-bold">{key}</dt>
                        <dd>{value}</dd>
                      </>
                    );
                  })}
                </dl>
              )}
              <Button
                className="w-full py-4 bg-cta"
                disabled={formData.loading}
                type="submit"
              >
                {formData.loading ? <>Saving</> : <>Save product</>}
              </Button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

const ProductStates = () => {
  return (
    <div className="space-y-2">
      <h2 className="text-2xl mb-4 mt-4">Store and Club availability</h2>
      <label className="flex items-center">
        <Field
          className="form-checkbox w-5 h-5 mr-2"
          type="checkbox"
          name="productData.store"
        />
        Available in store
      </label>
      <label className="flex items-center">
        <Field
          className="form-checkbox w-5 h-5 mr-2"
          type="checkbox"
          name="productData.online"
        />
        Available Online
      </label>
      <label className="flex items-center">
        <Field
          className="form-checkbox w-5 h-5 mr-2"
          type="checkbox"
          name="productData.club"
        />
        Club only
      </label>
    </div>
  );
};

const SyncStatus = ({ values, setFieldValue, dirty }) => {
  useEffect(() => {
    if (dirty) {
      setFieldValue("sync_status", false);
    }
  }, [dirty]);

  return (
    <div className="max-w-4xl w-full p-4 bg-brand-dark text-white flex justify-between items-center">
      {values?.last_edit ? (
        <div>
          Last sync:&nbsp;
          {dayjs(values?.last_edit).isBefore(dayjs(), "week") ? (
            <>{dayjs(values?.last_edit).format("YYYY-MM-DD HH:mm")}</>
          ) : (
            <>{dayjs(values?.last_edit).fromNow(true)}</>
          )}
        </div>
      ) : (
        <div />
      )}

      {values.sync_status && !dirty ? (
        <span className="tag tag-success">Synced</span>
      ) : (
        <span className="tag tag-fail">
          {values?.last_edit ? "Changed" : "New"}
        </span>
      )}
    </div>
  );
};

const VariantSettings = ({ values, setFieldValue, errors }) => {
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const regenerateVariants = async (e) => {
    e.preventDefault();
    toast("Starting Regeneration", {
      autoClose: true,
      position: toast.POSITION.TOP_RIGHT,
      type: toast.TYPE.INFO,
    });
    values.productData.variants = undefined;
    await sleep(200);
    toast("Removed old Data", {
      autoClose: true,
      position: toast.POSITION.TOP_RIGHT,
      type: toast.TYPE.INFO,
    });
    await sleep(500);
    if (values.productData["hasSizes"] && values.productData["hasColors"]) {
      values.productData["colors"].forEach((val, i) => {
        setFieldValue(`productData.variants.${i}.variant`, val);

        values.productData["sizes"].forEach((size, j) => {
          setFieldValue(`productData.variants.${i}.lvl2.${j}.variant`, size);

          if (
            values.productData.variants?.[i].lvl2[j].artno ===
              values.article_number ||
            !values.productData.variants?.[i]?.lvl2[j]?.artno
          ) {
            setFieldValue(
              `productData.variants.${i}.lvl2.${j}.artno`,
              `${values.article_number}-${val}-${size}`
            );
            setFieldValue(`productData.variants.${i}.lvl2.${j}.stock`, 0);
          }
          setFieldValue(
            `productData.variants.${i}.lvl2.${j}.price`,
            values?.productData?.basePrice
          );
          setFieldValue(
            `productData.variants.${i}.lvl2.${j}.discountprice`,
            values?.productData?.baseDiscountPrice
          );
        });
      });
    } else {
      if (values.productData["hasColors"]) {
        regenSingle("colors");
      }
      if (values.productData["hasSizes"]) {
        regenSingle("sizes");
      }
    }
    toast("Regenerated variants", {
      autoClose: true,
      position: toast.POSITION.TOP_RIGHT,
      type: toast.TYPE.INFO,
    });
    console.groupEnd();
  };

  const regenSingle = (attrib) => {
    values.productData[attrib].forEach((val, i) => {
      setFieldValue(`productData.variants.${i}.variant`, val);
      if (
        values?.productData?.variants?.[i]?.artno === values.article_number ||
        !values?.productData?.variants?.[i]?.artno
      ) {
        setFieldValue(
          `productData.variants.${i}.artno`,
          `${values.article_number}-${val}`
        );
        setFieldValue(`productData.variants.${i}.stock`, 0);
      }
      setFieldValue(
        `productData.variants.${i}.price`,
        values?.productData?.basePrice
      );
      setFieldValue(
        `productData.variants.${i}.discountprice`,
        values?.productData?.baseDiscountPrice
      );
    });
  };

  if (values.productData["hasColors"] && values.productData["hasSizes"]) {
    return (
      <MultiAttrib
        values={values}
        errors={errors}
        regenerateVariants={regenerateVariants}
      />
    );
  }

  if (values.productData["hasColors"]) {
    return (
      <SingleAttrib
        values={values}
        errors={errors}
        attrib="colors"
        regenerateVariants={regenerateVariants}
      />
    );
  }
  if (values.productData["hasSizes"]) {
    return (
      <SingleAttrib
        values={values}
        errors={errors}
        attrib="sizes"
        regenerateVariants={regenerateVariants}
      />
    );
  }
  return (
    <Field
      name="productData.baseStock"
      type="number"
      labelText="Stock"
      component={TextInput}
      validate={validateStock}
    />
  );
};

const SingleAttrib = ({ values, attrib, regenerateVariants, errors }) => {
  return (
    <>
      <div className="flex px-2 max-w-4xl w-full">
        <button
          onClick={regenerateVariants}
          className="p-4 bg-cta text-white mb-4"
        >
          Update variants
        </button>
        <p className="p-4 text-sm">
          (If you changed or added variants above you might need to trigger an
          update.)
        </p>
      </div>
      <FieldArray
        name={`productData.variants`}
        render={(arrayHelpers) => (
          <div className="space-y-4 px-2 max-w-4xl w-full">
            {values.productData[attrib].map((a, index) => (
              <div
                key={index}
                className="space-x-4 flex bg-gray-200 p-4 pb-0 focus-within:ring-4 items-center"
              >
                <Field
                  type="text"
                  name={`productData.variants.${index}.variant`}
                  component={TextInput}
                  readOnly={true}
                  labelText="Variant"
                />
                <Field
                  type="text"
                  name={`productData.variants.${index}.artno`}
                  component={TextInput}
                  labelText="Art.No."
                  helpText="Required"
                  validate={validateArtno}
                />
                <Field
                  type="number"
                  name={`productData.variants.${index}.price`}
                  component={TextInput}
                  labelText="Price"
                  validate={validateStock}
                />
                <Field
                  type="number"
                  name={`productData.variants.${index}.discountprice`}
                  component={TextInput}
                  labelText="Discount price"
                />
                <Field
                  type="number"
                  name={`productData.variants.${index}.stock`}
                  component={TextInput}
                  labelText="Stock"
                  validate={validateStock}
                />
                <Field
                  name={`productData.variants.${index}.imageUrl`}
                  component={VariantImage}
                />
              </div>
            ))}
          </div>
        )}
      />
    </>
  );
};

const MultiAttrib = ({ values, regenerateVariants, errors }) => {
  return (
    <>
      <div className="flex px-2 max-w-4xl w-full">
        <button
          onClick={regenerateVariants}
          className="p-4 bg-cta text-white mb-4"
        >
          Update variants
        </button>
        <p className="p-4 text-sm">
          (If you changed or added variants above you might need to trigger an
          update.)
        </p>
      </div>

      <FieldArray
        name={`productData.variants`}
        render={(arrayHelpers) => (
          <div className="space-y-4 px-2 max-w-4xl w-full">
            {values.productData["colors"].map((a, index) => (
              <div
                key={index}
                className="space-x-4 flex bg-gray-200 p-4 pb-0 focus-within:ring-4"
              >
                <div className="w-1/4">
                  <Field
                    type="text"
                    name={`productData.variants.${index}.variant`}
                    component={TextInput}
                    readOnly={true}
                    labelText="Variant"
                  />
                </div>
                <FieldArray
                  name={`productData.variants`}
                  render={(arrayHelpers) => (
                    <div className="space-y-4">
                      {values.productData["sizes"].map((a, subIndex) => (
                        <div key={subIndex} className="space-x-4 flex">
                          <Field
                            type="text"
                            name={`productData.variants.${index}.lvl2.${subIndex}.variant`}
                            component={TextInput}
                            readOnly={true}
                            labelText="Variant"
                          />
                          <Field
                            type="text"
                            name={`productData.variants.${index}.lvl2.${subIndex}.artno`}
                            component={TextInput}
                            helpText="Required"
                            errors={errors}
                            labelText="Art.No."
                            validate={validateArtno}
                          />
                          <Field
                            type="number"
                            name={`productData.variants.${index}.lvl2.${subIndex}.price`}
                            component={TextInput}
                            labelText="Price"
                            validate={validateStock}
                          />
                          <Field
                            type="number"
                            name={`productData.variants.${index}.lvl2.${subIndex}.discountprice`}
                            component={TextInput}
                            labelText="Discount price"
                          />
                          <Field
                            type="number"
                            name={`productData.variants.${index}.lvl2.${subIndex}.stock`}
                            component={TextInput}
                            validate={validateStock}
                            labelText="Stock"
                          />
                          <Field
                            name={`productData.variants.${index}.lvl2.${subIndex}.imageUrl`}
                            component={VariantImage}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
            ))}
          </div>
        )}
      />
    </>
  );
};

const VariantImage = ({ name, form, field }) => {
  const handleChange = (url) => {
    form.setFieldValue(field.name, url);
  };

  const [showImages, setShowImages] = useState(false);
  if (form?.values?.productData?.images?.length < 1) return null;
  return (
    <div
      onClick={() => setShowImages(!showImages)}
      className="relative w-16 bg-cta min-w-max p-2 rounded-md text-sm h-16 flex items-center shadow-none transition-shadow hover:shadow-md"
    >
      {field?.value ? (
        <img src={field?.value} className="h-12 object-contain rounded-sm" />
      ) : (
        <span>Link image</span>
      )}
      {showImages && (
        <div className="absolute bottom-0 right-0 bg-white rounded-lg shadow-lg p-4 w-screen max-w-xs">
          <h3 className="mb-2 text-lg font-bold">Select image</h3>
          <div className="space-x-4 overflow-scroll z-40 flex snap-x">
            {form?.values?.productData?.images.map((img) => (
              <img
                src={img.url}
                onClick={() => handleChange(img.url)}
                className="w-24 rounded-md shadow-none transition-shadow hover:shadow-md snap-center"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ListHandler = ({ attrib = "colors", values }) => {
  const { data } = useApiData("instance");
  const capt = attrib[0].toUpperCase() + attrib.slice(1);
  const hasCapt = "has" + capt;
  return (
    <div className="mb-4 p-4 bg-gray-200 focus-within:ring-4">
      <label
        className="py-4 flex justify-between items-center w-full text-xl"
        htmlFor={`productData.${hasCapt}`}
      >
        Use {attrib}
        <Field
          type="checkbox"
          id={`productData.${hasCapt}`}
          name={`productData.${hasCapt}`}
          className="w-5 h-5"
        />
      </label>
      <FieldArray
        name={`productData.${attrib}`}
        render={(arrayHelpers) => (
          <div>
            {values.productData[hasCapt] && (
              <div className="space-y-4">
                <h2 className="text-lg">{capt}</h2>
                {values.productData[attrib] &&
                  values.productData[attrib].map((a, index) => (
                    <div key={index} className="flex h-10">
                      {attrib === "colors" && data?.custom?.lock_colors ? (
                        <>
                          <div
                            className="w-10"
                            style={{
                              backgroundColor:
                                data.custom.colorList.find(
                                  (c) =>
                                    c[0] === values.productData[attrib][index]
                                )?.[1] ?? "#fff",
                            }}
                          ></div>
                          <Field
                            as="select"
                            name={`productData.${attrib}.${index}`}
                            type="text"
                          >
                            <option>-- Please select --</option>
                            {data?.custom?.colorList.map((color) => (
                              <option name={`productData.${attrib}.${index}`}>
                                {color[0]}
                              </option>
                            ))}
                          </Field>
                        </>
                      ) : (
                        <Field
                          name={`productData.${attrib}.${index}`}
                          type="text"
                          component={TextInput}
                        />
                      )}
                      <button
                        className="p-2 bg-danger w-10 text-white h-full border border-danger"
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        -
                      </button>
                    </div>
                  ))}

                <button
                  className="p-4 bg-success w-1/2 text-white max-w"
                  type="button"
                  onClick={() =>
                    attrib in values.productData &&
                    values.productData[attrib].length > 0
                      ? arrayHelpers.insert(
                          values.productData[attrib].length,
                          ""
                        )
                      : arrayHelpers.push("")
                  } // insert an empty string at a position: ;
                >
                  Add +
                </button>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};
