
import { useState,useEffect } from "react"
import {axios} from '../services/Api'



const useApiData = (path='',{filter=null,refetch=0,fallback=null}={}) => {
    const [hookState,setHookState] = useState({data:fallback,loading:false})

    async function fetchData() {
      setHookState({...hookState,loading:true})
      await axios.get('/api/stores/'+path).then((res)=>setHookState({...hookState,loading:false,data:res.data})).catch(()=>{
        setHookState({...hookState,loading:true,data:null})
        setTimeout(() => {
          fetchData()
        }, 2000);
      })
    }

    useEffect(()=>{

          fetchData();
          if(refetch){
        console.info(`Refetch activated ${refetch}s`)
          const interval = setInterval(() => {
            fetchData();
          }, refetch*1000);

          return () => clearInterval(interval);
        }
        

    },[path])

    
    return {...hookState,refetch:fetchData} 
}

export {useApiData}