import React, { useState } from "react";
import { axios } from "../services/Api";
import { useApiData } from "../utils/useApiData";
import { Formik, Field, Form } from "formik";
import { TextInput, Button } from "../forms/Inputs";
import { toast } from "react-toastify";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";

const Notifications = ({ token, setToken }) => {
  const { data } = useApiData("devices");
  const handleClick = (e) => {
    e.preventDefault();
    const firebaseConfig = {
      apiKey: "AIzaSyAgztIA6jn-uy54QT8IOZOxFUlr_oQvyF8",
      authDomain: "resellerportal-89668.firebaseapp.com",
      projectId: "resellerportal-89668",
      storageBucket: "resellerportal-89668.appspot.com",
      messagingSenderId: "500654498559",
      appId: "1:500654498559:web:58df387ed84c6bfbdd22ca",
      measurementId: "G-82NPCDCV4X",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging();

    getToken(messaging, {
      vapidKey:
        "BGotu8a7jjCfFxcHvdKTNUPpicjLCkNDv2w1l4tZ-E1QA97MiL63omeu09bZrv2JEIOnhsXModFMRPlvDqUMG4c",
    })
      .then((currentToken) => {
        if (currentToken) {
          setToken({ device_token: currentToken });
          const addData = { registration_id: currentToken, type: "web" };
          axios.post("/api/stores/devices/", addData).then((res) => {
            let type = toast.TYPE.SUCCESS;
            let message = "Device added for notifications";
            if (res.statusText !== "OK") {
              type = toast.TYPE.ERROR;
              message = "Unable to add device";
            }
            toast(message, {
              autoClose: false,
              position: toast.POSITION.TOP_RIGHT,
              type: type,
              autoClose: 5000,
            });
          });
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  };
  return (
    <>
      <h1 className="text-xl font-bold mb-2">Activate notifications</h1>
      <p>
        You have {data?.length} {data?.length === 1 ? "device" : "devices"}{" "}
        active.
      </p>
      <button
        className="bg-cta
       p-4 text-white rounded-md shadow-md hover:shadow-xl hover:bg-cta-dark transition-all"
        onClick={handleClick}
      >
        Activate this device
      </button>
    </>
  );
};

export const Profile = () => {
  const { data } = useApiData("user", { fallback: null });

  return (
    <div className="bg-white p-4 rounded-md shadow-md">
      <h1 className="text-xl font-bold mb-2">
        Profile for {data && `${data.user}`}
      </h1>

      {data ? <ProfileForm {...data} /> : "Loading..."}
    </div>
  );
};

const ProfileForm = (props) => {
  const [token, setToken] = useState({ device_token: props.device_token });
  // const [loading,setLoading] = useState(false)
  const [response, setResponse] = useState(null);
  return (
    <div className="space-y-8">
      <Formik
        initialValues={{ ...props, ...token }}
        enableReinitialize
        onSubmit={async (values) => {
          axios.post("/api/stores/user", { ...values }).then((res) => {
            setResponse(res.data);
            let type = toast.TYPE.SUCCESS;
            if (!res.data.success) {
              type = toast.TYPE.ERROR;
            }
            toast(res.data.message, {
              autoClose: false,
              position: toast.POSITION.TOP_RIGHT,
              type: type,
              autoClose: 5000,
            });
          });
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="space-y-4 flex flex-col">
            <Field
              type="text"
              style={{ marginBottom: "1rem" }}
              name="first_name"
              labelText="First name"
              component={TextInput}
            />
            <Field
              type="text"
              style={{ marginBottom: "1rem" }}
              name="last_name"
              labelText="Last name"
              component={TextInput}
            />
            <Field
              type="text"
              type="email"
              style={{ marginBottom: "1rem" }}
              name="email"
              labelText="E-mail"
              component={TextInput}
            />
            <h2 className="text-lg font-bold mb-2">Update password</h2>
            <Field
              type="password"
              style={{ marginBottom: "1rem" }}
              name="current_password"
              labelText="Current password"
              component={TextInput}
            />
            <Field
              type="password"
              style={{ marginBottom: "1rem" }}
              name="new_password"
              labelText="New password"
              component={TextInput}
            />

            <Button kind="primary" tabIndex={0} type="submit">
              Save
            </Button>

            {response && (
              <div
                onClose={() => setResponse(null)}
                kind={response.success ? "success" : "error"}
                subtitle={<span>{response.message}</span>}
                title={response.success ? "Success" : "Error"}
              />
            )}
          </Form>
        )}
      </Formik>
      <Notifications token={token} setToken={setToken} />
    </div>
  );
};
