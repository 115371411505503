import React, { useState } from "react";
import {Link} from 'react-router-dom'
import {useApiData} from '../utils/useApiData'
import {axios} from '../services/Api'
import { Formik, Field, Form, useFormik } from 'formik';
import { TextInput,Button } from "../forms/Inputs";
import * as Yup from 'yup';


const StoreSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .required('Required'),
  prefix: Yup.string()
    .min(2, 'Prefix should be 2-4 characters')
    .max(4, 'Prefix should be 2-4 characters')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const Stores = ({filter=null}) => {
  const {data} = useApiData('stores/')

    return (
      <div className="lg:flex space-x-6 bg-white p-4 rounded-md shadow-md">


    <div className="w-full lg:max-w-screen-sm  lg:w-1/3">
      <AddStore/>
      </div>
      <div className="lg:w-2/3">
        {data?.length > 0 &&
        <>
      <h2 className="text-2xl mb-4">Stores</h2>
      <table className="w-full">
        <tr>
          <th className="text-left p-2">
            Name
            </th>
            <th className="text-left p-2">
              Prefix
              </th>
            </tr>
        {data.map(store => <tr><td>{store.name}</td><td>{store.prefix}</td></tr>)}
      </table>
      </>}
      </div>

      </div>
    )
  }

const AddStore = ({filter=null}) => {
  const [state,setState] = useState(null)
  
  const handleResponse = (res) => {
    setState(res.data)
  }

  return (
  <>
      <h2 className="text-2xl mb-4">Add store</h2>
      {state && 
      <div className={`border-2 border-solid rounded-md p-4 mb-4 text-center ${state.success ? 'text-green-600 border-green-600' : 'text-red-600 border-red-600'}`}>{state?.message}</div>
      }
      {state?.success && <button onClick={()=>setState(null)} className="bg-black p-4 text-white rounded-md">Add another store</button>}
      {!state?.success &&
      <Formik
        validationSchema={StoreSchema}
        initialValues={{
          'name':'',
          'prefix':''
        }}
        enableReinitialize
        onSubmit={async (values) => {
            axios.post('/api/stores/create_store', values)
            .then(handleResponse)
            .catch(function (error) {
              console.log(error);
            });
        }}
      >
        {({ values,setFieldValue,errors }) => (
          <Form className="space-y-4 flex flex-col items-center w-full">
              <Field required type="text" errors={errors} name="name" labelText="Name" helpText="The name of the store and the main user" component={TextInput} required/>
              <Field required type="text" errors={errors} name="prefix" labelText="Prefix" helpText="Prefix for articlenumbers" component={TextInput} required/>
              <Field required type="email" errors={errors} name="email" labelText="E-mail" helpText="Store e-mail" component={TextInput} required/>

              <Field required type="password" errors={errors} name="password" labelText="Password" component={TextInput} required/>
              <Field required type="password" errors={errors} name="passwordConfirmation" labelText="Confirm Password" component={TextInput} required/>
              <Button type="submit">Add Store</Button>
          </Form>
        )}
      </Formik>}
</>
    )
    }