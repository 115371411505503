import React, { useContext, useState } from "react";
import { login } from "../services/Api";
import { AuthContext } from "../services/Auth";
import { Redirect } from "react-router-dom";
import { TextInput } from "../forms/Inputs";
const Login = () => {
  const { updateAuth, authenticated, instance } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const updateUsername = (e) => {
    setUsername(e.target.value);
  };
  const updatePassword = (e) => {
    setPassword(e.target.value);
  };

  const doLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    login({
      username: username,
      password: password,
    })
      .catch((e) => {
        console.log(e);
        setErrors(e);
        setLoading(false);
      })
      .finally(() => {
        console.log("Jadå");
        updateAuth();
      });
  };

  if (authenticated) {
    return <Redirect to="/" />;
  }

  return (
    <form>
      <div className="login-form bg-gradient-to-br from-brand to-brand-light">
        <div className="from-emerald-300 to-emerald-500 bg-gradient-to-bl shadow py-10 text-white text-center text-xl">
          Try our new updated v2.0 dashboard located at{" "}
          <a className="underline font-bold " href="https://app.inviiv.io">
            app.inviiv.io
          </a>
        </div>
        <button onClick={doLogin} className="hidden" type="submit">
          Login
        </button>
        <div className="max-w-md  flex flex-col justify-center items-center space-y-4 p-8 h-screen">
          {instance?.logo ? (
            <img src={instance.logo} alt="logo" className="w-60" />
          ) : (
            <h1 className="text-3xl font-bold">INVIIV</h1>
          )}

          <TextInput
            type="text"
            id="username"
            placeholder="Username"
            onChange={updateUsername}
            invalid={errors}
            disabled={loading}
          />
          <TextInput
            type="password"
            placeholder="Password"
            id="password"
            onChange={updatePassword}
            invalid={errors}
            disabled={loading}
          />
          {errors && (
            <div className="bg-red-100 border-red-700 text-red-700 w-full text-center border-solid border-2 rounded-md p-4">
              {errors}
            </div>
          )}
          <button
            onClick={doLogin}
            className="bg-cta shadow-md text-xl p-2 w-full text-white hover:bg-cta-dark"
            kind="primary"
            tabIndex={0}
            type="submit"
          >
            Login
          </button>
          <a
            href="https://status.inviiv.io/"
            className="statusLink"
            target="_blank"
          >
            Status & Uptime info
          </a>
        </div>
      </div>
    </form>
  );
};

export default Login;
