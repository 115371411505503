import { useState } from "react";
import { ErrorMessage } from "formik";

const Eye = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);

const EyeOff = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
    />
  </svg>
);

export const RequiredMarker = ({ children }) => (
  <span className="text-red-500">*</span>
);

export const TextInput = (props) => {
  const [type, setType] = useState(props.type);
  const typeToggle = (e) => {
    e.preventDefault();
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  return (
    <div className="relative w-full">
      {props.labelText && (
        <label className="block pb-2">
          {props.labelText}
          {props.required && <RequiredMarker />}
        </label>
      )}
      <div className="relative text-xl w-full mb-6">
        <input
          disabled={props.disabled}
          readOnly={props.readOnly ?? false}
          onChange={props.onChange}
          {...props.field}
          className={`form-input w-full h-10 rounded-md`}
          type={type}
        />
        {props.errors && props.field?.name in props.errors && (
          <div className="absolute top-10 left-0 p-1 h-6 text-xs bg-danger text-white font-bold w-full">
            {props.errors[props.field?.name]}
          </div>
        )}
        {props.type === "password" && (
          <span
            className="bg-black flex items-center justify-center fill-current text-white absolute top-0 right-0 bottom-0 w-8 cursor-pointer hover:bg-gray-900"
            onClick={typeToggle}
          >
            {type === "text" ? <EyeOff /> : <Eye />}
          </span>
        )}
      </div>
      {props.helpText && (
        <span className="block p-1 text-xs -mt-6">{props.helpText}</span>
      )}
      {props.field?.name && (
        <ErrorMessage name={props.field?.name}>
          {(msg) => <div className="py-2 mb-6 text-danger">{msg}</div>}
        </ErrorMessage>
      )}
    </div>
  );
};

export const TextArea = (props) => {
  return (
    <div>
      {props.labelText && (
        <label className="block pb-2">{props.labelText}</label>
      )}
      <div className="relativeshadow-md text-xl overflow-hidden w-full mb-6">
        <textarea
          onChange={props.onChange}
          {...props.field}
          className="form-input w-full"
          {...props}
        />
      </div>
      {props.helpText && (
        <span className="block p-1 text-xs -mt-6 mb-6">{props.helpText}</span>
      )}
    </div>
  );
};

export const Button = (props) => {
  return (
    <button
      {...props}
      className={
        "bg-black shadow-md text-xl p-2 w-80 text-white " + props.className
      }
    />
  );
};
