import {
  applyAuthTokenInterceptor,
  clearAuthTokens,
  setAuthTokens,
  isLoggedIn,
} from "axios-jwt";
import axios from "axios";

export let BASE_URL = "";
if (process.env.NODE_ENV === "development") {
  BASE_URL = "http://localhost:8000";
}

// 1. Create an axios instance that you wish to apply the interceptor to
const axiosInstance = axios.create({ baseURL: BASE_URL });

const requestRefresh = (refresh) => {
  return axios
    .post(`${BASE_URL}/api/token/refresh/`, { refresh })
    .then((response) => {
      return response.data.access;
    })
    .catch((e) => {
      console.log("Det sket sig", e);
      logout();
    });
};

applyAuthTokenInterceptor(axiosInstance, { requestRefresh });

const login = async (params) => {
  const response = await axiosInstance
    .post("/api/token/", params)
    .catch((e) => {
      throw "The login information you entered is incorrect";
    });
  // save tokens to storage
  setAuthTokens({
    accessToken: response.data.access,
    refreshToken: response.data.refresh,
  });
};

// 5. Logging out
const logout = () => clearAuthTokens();

window.login = login;
window.logout = logout;
window.isLoggedIn = isLoggedIn;

export { axiosInstance as axios, login, logout, isLoggedIn };
