import {useApiData} from '../utils/useApiData'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import { useEffect } from 'react';

export const StoreSelect = ({values,setFieldValue}) => {
  const { data } = useApiData('stores/');



  useEffect(() => {
    if(data?.length === 1){
      setFieldValue('store', data[0].id)
    }
    
  },[data?.length])


  if(!data) return null

  if(data?.length === 1) return <Field name="store" type="hidden"/>

  function validateStore(value) {
    let error;
    if (value==="-- Select store --") {
      error = 'You need to select a store';
    }
    return error;
  }


  return (<div>
    <h2 className="text-2xl mb-4 mt-8">Select vendor</h2>
    <Field as="select" name="store" className="input-select w-full" validate={validateStore}>
      <option value={null}>-- Select store --</option>
      {data.map(v =>       
            <option key={v.prefix} value={v.id}>{v.name} - [{v.prefix}]</option>
      )}
    </Field>
    <ErrorMessage name="store" component="div" className="text-red-500" />
  </div>);
};
