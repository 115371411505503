import { extendTheme } from "./extend";


export default extendTheme({
  backgroundPrimary: '#f9f5f4',
  brandLight: '#a1acb5',
  brand: '#163046',
  brandDark: '#112435',
  ctaLight: '#fad6a3',
  cta: '#f3991a',
  ctaDark: '#b67314',
});