import { useState,useEffect } from "react"
export const Loader = ()=> {
    const [message,setMessage] = useState('Loading...')
    useEffect(()=>{
        setTimeout(() => {
            setMessage('Just a second...')
        }, 3000);
    },[])

    return <><div className="loader"/><div>{message}</div></>
}